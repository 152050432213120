import { getMemberApprover, getUserRole } from 'helper/utility/customFunctions';
import { ROLES, UserLevel } from 'helper/config';

const userData = () => JSON.parse(localStorage.getItem('userData')) ?? '';

type ProfileTabTitleTYpe = {
  [key: string]: string;
};

interface ProfileTabHeadersType {
  id: number;
  icon: string;
  name: string;
  info: string;
  permission: number[];
  userLevel?: number[];
}

export interface TabbingInitialType {
  tabbing: {
    user_profile: {
      active: string;
    };
    company_profile: {
      active: string;
    };
    job_details: {
      active: string;
    };
    my_tasks: {
      active: string;
    };
    utm: {
      active: string;
    };
  };
}

export const profileTabTitle: ProfileTabTitleTYpe = {
  ABOUT: 'About',
  COMMUNICATION: 'Communication',
  COMPANIES: 'Companies',
  ACCOUNT_SETTINGS: 'Account Settings',
  NDA: 'NDA'
};

export const myTasksTabTitle: ProfileTabTitleTYpe = {
  TASK_DETAILS: 'Task Details',
  ASSETS: 'Assets'
};

export const companyProfileTabTitle: ProfileTabTitleTYpe = {
  COMPANY_INFO: 'Company Info',
  CHANNEL_SETTINGS: 'Channel Settings',
  USERS: 'Users',
  APPROVAL_WORKFLOWS: 'Approval Workflows',
  JOBS: 'Jobs',
  JOB_TEMPLATE: 'Job Template',
  WORKFLOW: 'Workflow',
  MEDIA_APPROVAL_WORKFLOW: 'Media Approval Workflow',
  MEMBERS: 'Members',
  NDA: 'NDA'
};

export const JobDetailsTabTitle: ProfileTabTitleTYpe = {
  JOB_DETAILS: 'Job Details',
  ACTIVITY: 'Activity',
  FILES: 'Files',
  PROPOSALS: 'Proposals',
  QUESTION_ANSWERS: 'Question And Answers',
  UTM_TEMPLATES: 'UTM Templates',
  EXTERNAL_SOURCE_INFO: 'External Source Information',
  RELATED_JOBS_INFO: 'Related Jobs Information'
};

export const profileTabHeaders: ProfileTabHeadersType[] = [
  {
    id: 0,
    icon: 'InfoOutlined',
    name: profileTabTitle.ABOUT,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER]
  },
  {
    id: 1,
    icon: 'CallOutlined',
    name: profileTabTitle.COMMUNICATION,
    info: '',
    permission: [ROLES.AGENCY, ROLES.MEMBER]
  },
  {
    id: 2,
    icon: 'BusinessOutlined',
    name: profileTabTitle.COMPANIES,
    info: '',
    permission: [ROLES.AGENCY]
  },
  {
    id: 2,
    icon: 'SettingsOutlined',
    name: profileTabTitle.ACCOUNT_SETTINGS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER]
  },
  {
    id: 4,
    icon: 'NoteAltOutlined',
    name: profileTabTitle.NDA,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER]
  }
];

export const myTasksTabHeaders: ProfileTabHeadersType[] = [
  {
    id: 0,
    icon: 'TaskAltOutlined',
    name: myTasksTabTitle.TASK_DETAILS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN]
  },
  {
    id: 1,
    icon: 'ShowChart',
    name: myTasksTabTitle.ASSETS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN]
  }
];

export const companyProfileTabHeaders: ProfileTabHeadersType[] = [
  {
    id: 0,
    icon: 'InfoOutlined',
    name: companyProfileTabTitle.COMPANY_INFO,
    info: '',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 1,
    icon: 'DisplaySettingsOutlined',
    name: companyProfileTabTitle.CHANNEL_SETTINGS,
    info: '',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 2,
    icon: 'PeopleAltOutlined',
    name: companyProfileTabTitle.USERS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 3,
    icon: 'GridViewOutlined',
    name: companyProfileTabTitle.APPROVAL_WORKFLOWS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 4,
    icon: 'BusinessCenterOutlined',
    name: companyProfileTabTitle.JOBS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 5,
    icon: 'ReceiptLongOutlined',
    name: companyProfileTabTitle.JOB_TEMPLATE,
    info: '',
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 6,
    icon: 'GridViewOutlined',
    name: companyProfileTabTitle.WORKFLOW,
    info: '',
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    id: 7,
    icon: 'PeopleAltOutlined',
    name: companyProfileTabTitle.MEMBERS,
    info: '',
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    id: 8,
    icon: 'PhotoLibraryOutlined',
    name: companyProfileTabTitle.MEDIA_APPROVAL_WORKFLOW,
    info: '',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    id: 9,
    icon: 'NoteAltOutlined',
    name: companyProfileTabTitle.NDA,
    info: '',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  }
];

export const JobDetailsTabHeaders: ProfileTabHeadersType[] = [
  {
    id: 0,
    icon: 'InfoOutlined',
    name: JobDetailsTabTitle.JOB_DETAILS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 1,
    icon: 'PeopleAltOutlined',
    name: JobDetailsTabTitle.ACTIVITY,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 3,
    icon: 'AccountTreeOutlined',
    name: JobDetailsTabTitle.FILES,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 4,
    icon: 'DescriptionOutlined',
    name: JobDetailsTabTitle.PROPOSALS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    id: 5,
    icon: 'PostAddOutlined',
    name: JobDetailsTabTitle.QUESTION_ANSWERS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 6,
    icon: 'ViewTimelineOutlined',
    name: JobDetailsTabTitle.UTM_TEMPLATES,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 6,
    icon: 'OpenInNewOutlined',
    name: JobDetailsTabTitle.EXTERNAL_SOURCE_INFO,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.CREATOR, ROLES.MEMBER],
    userLevel: []
  },
  {
    id: 7,
    icon: 'HubOutlined',
    name: JobDetailsTabTitle.RELATED_JOBS_INFO,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  }
];

export const utmTemplateTabTitles: ProfileTabTitleTYpe = {
  TEMPLATE_DETAILS: 'Template Details',
  ACTIVITY: 'Activity',
  PROPOSALS: 'Proposals'
};

export const utmtemplatetabheaders: ProfileTabHeadersType[] = [
  {
    id: 0,
    icon: 'InfoOutlined',
    name: utmTemplateTabTitles.TEMPLATE_DETAILS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER]
  },
  {
    id: 1,
    icon: 'DisplaySettingsOutlined',
    name: utmTemplateTabTitles.ACTIVITY,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER]
  },
  {
    id: 2,
    icon: 'DescriptionOutlined',
    name: utmTemplateTabTitles.PROPOSALS,
    info: '',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  }
];
