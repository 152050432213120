import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  relatedJobsList: {
    loading: false,
    data: {
      count: 0,
      next: null,
      prev: null,
      results: []
    }
  }
};

export const relatedJobsSlice = createSlice({
  name: 'relatedJobs',
  initialState,
  reducers: {
    SET_RELATED_JOBS_MAIN_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_RELATED_JOBS_LIST_LOADING: (state, action) => ({
      ...state,
      relatedJobsList: {
        ...state.relatedJobsList,
        loading: action.payload
      }
    }),

    SET_RELATED_JOBS_LIST_DATA: (state, action) => {
      return {
        ...state,
        relatedJobsList: {
          ...state.relatedJobsList,
          hasData: true,
          data: action.payload
        }
      };
    }
  }
});

export const { SET_RELATED_JOBS_MAIN_LOADING, SET_RELATED_JOBS_LIST_LOADING, SET_RELATED_JOBS_LIST_DATA } =
  relatedJobsSlice.actions;

export const RELATED_JOBS_LIST = (state: RootState) => state.relatedJobs.relatedJobsList.data;
