import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  loading: false,
  agencyInternalJobsList: {
    loading: false,
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  }
};

export const internalJobsListSlice = createSlice({
  name: 'internalJobsList',
  initialState,
  reducers: {
    SET_INTERNAL_JOBS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_AGENCY_INTERNAL_JOBS_LIST_LOADING: (state, action) => ({
      ...state,
      agencyInternalJobsList: {
        ...state.agencyInternalJobsList,
        loading: action.payload
      }
    }),

    SET_AGENCY_INTERNAL_JOBS_DATA: (state, action) => ({
      ...state,
      agencyInternalJobsList: {
        ...state.agencyInternalJobsList,
        data: action.payload
      }
    }),

    CLEAR_INTERNAL_JOBS: () => ({
      ...initialState
    })
  }
});

export const {
  SET_INTERNAL_JOBS_LOADING,
  SET_AGENCY_INTERNAL_JOBS_LIST_LOADING,
  SET_AGENCY_INTERNAL_JOBS_DATA,
  CLEAR_INTERNAL_JOBS
} = internalJobsListSlice.actions;

export const AGENCY_INTERNAL_JOBS_DATA = (state: RootState) => state.homePage.internalJobsList.agencyInternalJobsList;
