import { createSlice } from '@reduxjs/toolkit';
import { addSwipeLeftListener } from '../../../Editor/utils/swipe';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  SuperAdminDamShareReducer: {
    loading: false,
    success: false,
    shareDAM: null
  },
  SuperAdminDamReducer: {
    loading: false,
    success: false,
    DamData: null
  },
  SuperAdminDamimageReducer: {
    loading: false,
    success: false,
    DamImageData: null
  },
  SuperAdminRootDamReducer: {
    loading: false,
    success: false,
    RootDamData: null
  },
  SuperAdminDamIDReducer: {
    loading: false,
    success: false,
    DamData1: null
  },
  SuperAdminDamIDIMAGESReducer: {
    loading: false,
    success: false,
    DamDataImages: null
  },
  SuperAdminDamIDDocumentsReducer: {
    loading: false,
    success: false,
    DamDataDocuments: null
  },
  SuperAdminDamIDCollectionReducer: {
    loading: false,
    success: false,
    DamDataCollectionId: null
  },
  SuperAdminPostDamReducer: {
    loading: false,
    success: false,
    DamPostData: null
  },
  SuperAdminPostDamParentReducer: {
    loading: false,
    success: false,
    DamPostParentData: null
  },
  SuperAdminPostDamCollectionReducer: {
    loading: false,
    success: false,
    DamPostCollection: null
  },
  SuperAdminPostDamIdCollectionReducer: {
    loading: false,
    success: false,
    DamPostCollectionId: null
  },
  SuperAdminDamCollectionReducer: {
    loading: false,
    success: false,
    DamPostCollectionData: null
  },
  SuperAdminDamDetailsReducer: {
    loading: false,
    success: false,
    DamDetails: null
  },
  SuperAdminDamDeleteReducer: {
    loading: false,
    success: true
  },
  SuperAdminDamRootImages: {
    loading: false,
    success: false,
    DamRootImages: null
  },
  SuperAdminDamRootDocuments: {
    loading: false,
    success: false,
    DamRootDocuments: null
  },
  SuperAdminDamParentCollection: {
    loading: false,
    success: false,
    DamCollectionParentImages: null
  },
  SuperAdminFavourite: {
    loading: false,
    success: false,
    FavouriteData: null
  },
  SuperAdminTitlereducer: {
    loading: false,
    success: true
  },
  SuperAdminCollectionDelete: {
    loading: false,
    success: false,
    DeletecollectionData: null
  },
  SuperAdminCollectionviewreducer: {
    loading: false,
    success: false,
    Collectionviewdata: []
  },
  SuperAdminCollectionfilespostreducer: {
    loading: false,
    success: false,
    Collectionfilesviewdata: null
  },
  SuperAdminMassTagspostreducer: {
    loading: false,
    success: false,
    MassTagsviewdata: null
  },
  SuperAdminDamMovepostreducer: {
    loading: false,
    success: false,
    Movedata: null
  },
  SuperAdmindamfoldermovereducer: {
    loading: false,
    success: false,
    Dammovefolder: null
  },
  SuperAdmindamrootfoldermovereducer: {
    loading: false,
    success: false,
    DamROOTmovefolder: null
  },
  SuperAdmindamcopyreducer: {
    loading: false,
    success: false,
    DamROOTCopyfolder: null
  },
  SuperAdmindamrenamereducer: {
    loading: false,
    success: false,
    DamRenamefolder: null
  },
  SuperAdmindamcollectionmovereducer: {
    loading: false,
    success: false,
    Damcollectioncopyfolder: null
  },
  SuperAdmindamcollectionIDmovereducer: {
    loading: false,
    success: false,
    Damcollectioncopyfolder1: null
  },
  SuperAdminDamcollectionMultipleDelete: {
    loading: false,
    success: false,
    DamMultipleDelete: null
  },
  SuperAdminDamMultipleMove: {
    loading: false,
    success: false,
    DamMultipleMove: null
  },
  SuperAdminDamSearch: {
    loading: false,
    success: false,
    DamSearch1: null
  },
  SuperAdminDamSearchfolder: {
    loading: false,
    success: false,
    DamSearchnew: []
  },
  SuperAdminDamInsideMove: {
    loading: false,
    success: false,
    DamCollectioninside: null
  },
  SuperAdminDamVideo: {
    loading: false,
    success: false,
    DamVideo: null
  },
  SuperAdminDamFilterFavourateID: {
    loading: false,
    success: false,
    DamFilterFavourateId: null
  },
  SuperAdminDamFilterFavourate: {
    loading: false,
    success: false,
    DamFiltering: null
  },
  SuperAdminDamFilterFavourateCount: {
    loading: false,
    success: false,
    DamCountData: null
  },
  SuperAdminDamFilterFavourateCountID: {
    loading: false,
    success: false,
    DamCountDataid: null
  },
  SuperAdminlistAllMostUsed: {
    loading: false,
    success: false,
    Dammostused: null
  },
  SuperAdminAllParentFilter: {
    loading: false,
    success: false,
    DamParentFilter: null
  },
  SuperAdminCompanyreducer: {
    loading: false,
    success: false,
    DamCompany: null
  },
  SuperAdminCompanyIDreducer: {
    loading: false,
    success: false,
    DamIdCompany: null
  },
  SuperAdminCompanyCountreducer: {
    loading: false,
    success: false,
    DamIdCompanylist: null
  },
  SuperAdminCompanyCountIDreducer: {
    loading: false,
    success: false,
    DamIdCompanydatalist: null
  },
  AdminDaminsideData: {
    loading: false,
    success: false,
    InsideData: null
  },
  AdminCollectionDamFilter: {
    loading: false,
    success: false,
    CollectionDamFilter: null
  },
  AdminCollectionsearchFilter: {
    loading: false,
    success: false,
    CollectionDamSearch: null
  },
  AdminCollectioncountFilter: {
    loading: false,
    success: false,
    CollectionDamCount: null
  },
  memberAdminCompanyListReducer: {
    loading: false,
    success: false,
    memberCompanyAdmin: null,
    agecyIdCompany: null,
    agecyNameCompany: null
  }
};

export const adminMedia = createSlice({
  name: 'admimMedia',
  initialState,
  reducers: {
    SET_ADMIN_MEDIA_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    ADMIN_DAM_SHARE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamShareReducer: {
        ...state.SuperAdminDamShareReducer,
        loading: false,
        success: true,
        shareDAM: action.payload.message
      }
    }),
    ADMIN_RESET_SHARE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamShareReducer: {
        ...state.SuperAdminDamShareReducer,
        loading: false,
        success: false,
        shareDAM: null
      }
    }),

    ADMIN_DAM_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamReducer: {
        ...state.SuperAdminDamReducer,
        loading: false,
        success: true,
        DamData: action.payload
      }
    }),

    ADMIN_DAM_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamimageReducer: {
        ...state.SuperAdminDamimageReducer,
        loading: false,
        success: true,
        DamImageData: action.payload
      }
    }),

    ADMIN_DAM_ROOT_LIST_LOADER: (state, action) => ({
      ...state,
      SuperAdminRootDamReducer: {
        ...state.SuperAdminRootDamReducer,
        loading: action.payload
      }
    }),

    ADMIN_DAM_ROOT_LIST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminRootDamReducer: {
        ...state.SuperAdminRootDamReducer,
        loading: false,
        success: true,
        RootDamData: action.payload
      }
    }),

    ADMIN_DAM_ID_LIST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamIDReducer: {
        ...state.SuperAdminDamIDReducer,
        loading: false,
        success: true,
        DamData1: action.payload
      }
    }),

    ADMIN_DAM_ID_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamIDIMAGESReducer: {
        ...state.SuperAdminDamIDIMAGESReducer,
        loading: false,
        success: true,
        DamDataImages: action.payload
      }
    }),

    ADMIN_DAM_DOCUMENTS_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamIDDocumentsReducer: {
        ...state.SuperAdminDamIDDocumentsReducer,
        loading: false,
        success: true,
        DamDataDocuments: action.payload
      }
    }),

    ADMIN_DAM_COLLECTION_ID_LIST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamIDCollectionReducer: {
        ...state.SuperAdminDamIDCollectionReducer,
        loading: false,
        success: true,
        DamDataCollectionId: action.payload
      }
    }),

    ADMIN_DAM_LIST_POST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminPostDamReducer: {
        ...state.SuperAdminPostDamReducer,
        loading: false,
        success: true,
        DamPostData: action.payload
      }
    }),

    ADMIN_DAM_PARENT_LIST_POST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminPostDamParentReducer: {
        ...state.SuperAdminPostDamParentReducer,
        loading: false,
        success: true,
        DamPostParentData: action.payload
      }
    }),

    ADMIN_DAM_POST_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminPostDamCollectionReducer: {
        ...state.SuperAdminPostDamCollectionReducer,
        loading: false,
        success: true,
        DamPostCollection: action.payload
      }
    }),

    ADMIN_DAM_POST_ID_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminPostDamIdCollectionReducer: {
        ...state.SuperAdminPostDamIdCollectionReducer,
        loading: false,
        success: true,
        DamPostCollectionId: action.payload
      }
    }),

    ADMIN_DAM_LIST_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamCollectionReducer: {
        ...state.SuperAdminDamCollectionReducer,
        loading: false,
        success: true,
        DamPostCollectionData: action.payload
      }
    }),

    ADMIN_DAM_DETAILS_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamDetailsReducer: {
        ...state.SuperAdminDamDetailsReducer,
        loading: false,
        success: true,
        DamDetails: action.payload
      }
    }),

    ADMIN_DAM_DELETE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamDeleteReducer: {
        ...state.SuperAdminDamDeleteReducer,
        success: true,
        loading: action.payload
      }
    }),

    ADMIN_DAM_ROOT_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamRootImages: {
        ...state.SuperAdminDamRootImages,
        loading: false,
        success: true,
        DamRootImages: action.payload
      }
    }),

    ADMIN_DAM_ROOT_DOCUMENTS_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamRootDocuments: {
        ...state.SuperAdminDamRootDocuments,
        loading: false,
        success: true,
        DamRootDocuments: action.payload
      }
    }),

    ADMIN_DAM_COLLECTION_LIST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamParentCollection: {
        ...state.SuperAdminDamParentCollection,
        loading: false,
        success: true,
        DamCollectionParentImages: action.payload
      }
    }),

    ADMIN_DAM_Favourite_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminFavourite: {
        ...state.SuperAdminFavourite,
        loading: false,
        success: true,
        FavouriteData: action.payload
      }
    }),

    ADMIN_DAM_TITLE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminTitlereducer: {
        ...state.SuperAdminTitlereducer,
        loading: false,
        success: action.payload
      }
    }),

    ADMIN_COLLECTION_DELETE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCollectionDelete: {
        ...state.SuperAdminCollectionDelete,
        loading: false,
        success: true,
        DeletecollectionData: action.payload
      }
    }),
    ADMIN_COLLECTION_DELETE_LOADER: (state, action) => ({
      ...state,
      SuperAdminCollectionDelete: {
        ...state.SuperAdminCollectionDelete,
        loading: action.payload
      }
    }),

    ADMIN_COLLECTION_VIEW_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCollectionviewreducer: {
        ...state.SuperAdminCollectionviewreducer,
        loading: false,
        success: true,
        Collectionviewdata: action.payload
      }
    }),

    ADMIN_COLLECTION_FILES_POST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCollectionfilespostreducer: {
        ...state.SuperAdminCollectionfilespostreducer,
        loading: false,
        success: true,
        Collectionfilesviewdata: action.payload
      }
    }),

    ADMIN_MASS_TAGS_POST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminMassTagspostreducer: {
        ...state.SuperAdminMassTagspostreducer,
        loading: false,
        success: true,
        MassTagsviewdata: action.payload
      }
    }),

    ADMIN_MOVE_FILES_POST_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamMovepostreducer: {
        ...state.SuperAdminDamMovepostreducer,
        loading: false,
        success: true,
        Movedata: action.payload
      }
    }),

    ADMIN_DAM_MOVE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamfoldermovereducer: {
        ...state.SuperAdmindamfoldermovereducer,
        loading: false,
        success: true,
        Dammovefolder: action.payload
      }
    }),

    ADMIN_DAM_ROOT_MOVE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamrootfoldermovereducer: {
        ...state.SuperAdmindamrootfoldermovereducer,
        loading: false,
        success: true,
        DamROOTmovefolder: action.payload
      }
    }),

    ADMIN_DAM_ROOT_COPY_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamcopyreducer: {
        ...state.SuperAdmindamcopyreducer,
        loading: false,
        success: true,
        DamROOTCopyfolder: action.payload
      }
    }),

    ADMIN_DAM_RENAME_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamrenamereducer: {
        ...state.SuperAdmindamrenamereducer,
        loading: false,
        success: true,
        DamRenamefolder: action.payload
      }
    }),

    ADMIN_DAM_COLLECTION_COPY_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamcollectionmovereducer: {
        ...state.SuperAdmindamcollectionmovereducer,
        loading: false,
        success: true,
        Damcollectioncopyfolder: action.payload
      }
    }),

    ADMIN_DAM_COLLECTION_COPY_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdmindamcollectionIDmovereducer: {
        ...state.SuperAdmindamcollectionIDmovereducer,
        loading: false,
        success: true,
        Damcollectioncopyfolder1: action.payload
      }
    }),

    ADMIN_DAM_COLLECTION_MULTIPLE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamcollectionMultipleDelete: {
        ...state.SuperAdminDamcollectionMultipleDelete,
        loading: false,
        success: true,
        DamMultipleDelete: action.payload
      }
    }),

    ADMIN_DAM_MOVE_MULTIPLE_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamMultipleMove: {
        ...state.SuperAdminDamMultipleMove,
        loading: false,
        success: true,
        DamMultipleMove: action.payload
      }
    }),

    ADMIN_DAM_SEARCH_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamSearch: {
        ...state.SuperAdminDamSearch,
        loading: false,
        success: true,
        DamSearch1: action.payload
      }
    }),

    ADMIN_DAM_SEARCH1_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamSearchfolder: {
        ...state.SuperAdminDamSearchfolder,
        loading: false,
        success: true,
        DamSearchnew: action.payload
      }
    }),

    ADMIN_DAM_MOVE_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamInsideMove: {
        ...state.SuperAdminDamInsideMove,
        loading: false,
        success: true,
        DamCollectioninside: action.payload
      }
    }),

    ADMIN_DAM_VIDEOS_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamVideo: {
        ...state.SuperAdminDamVideo,
        loading: false,
        success: true,
        DamVideo: action.payload
      }
    }),

    ADMIN_DAM_FAVOURATE_FILTER_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamFilterFavourateID: {
        ...state.SuperAdminDamFilterFavourateID,
        loading: false,
        success: true,
        DamFilterFavourateId: action.payload
      }
    }),

    ADMIN_DAM_FAVOURATE_FILTER_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamFilterFavourate: {
        ...state.SuperAdminDamFilterFavourate,
        loading: false,
        success: true,
        DamFiltering: action.payload
      }
    }),

    ADMIN_DAM_FAVOURATE_FILTER_COUNT_LOADER: (state, action) => ({
      ...state,
      SuperAdminDamFilterFavourateCount: {
        ...state.SuperAdminDamFilterFavourateCount,
        loading: action.payload
      }
    }),

    ADMIN_DAM_FAVOURATE_FILTER_COUNT_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamFilterFavourateCount: {
        ...state.SuperAdminDamFilterFavourateCount,
        loading: false,
        success: true,
        DamCountData: action.payload
      }
    }),

    ADMIN_DAM_FAVOURATE_FILTER_COUNT_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminDamFilterFavourateCountID: {
        ...state.SuperAdminDamFilterFavourateCountID,
        loading: false,
        success: true,
        DamCountDataid: action.payload
      }
    }),

    ADMIN_DAM_MostUsed_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminlistAllMostUsed: {
        ...state.SuperAdminlistAllMostUsed,
        loading: false,
        success: true,
        Dammostused: action.payload
      }
    }),

    ADMIN_DAM_PARENT_FILTER_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminAllParentFilter: {
        ...state.SuperAdminAllParentFilter,
        loading: false,
        success: true,
        DamParentFilter: action.payload
      }
    }),

    ADMIN_DAM_Company_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCompanyreducer: {
        ...state.SuperAdminCompanyreducer,
        loading: false,
        success: true,
        DamCompany: action.payload
      }
    }),

    ADMIN_DAM_Company_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCompanyIDreducer: {
        ...state.SuperAdminCompanyIDreducer,
        loading: false,
        success: true,
        DamIdCompany: action.payload
      }
    }),

    ADMIN_DAM_Company_COUNT_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCompanyCountreducer: {
        ...state.SuperAdminCompanyCountreducer,
        loading: false,
        success: true,
        DamIdCompanylist: action.payload
      }
    }),

    ADMIN_DAM_Company_COUNT_ID_SUCCESS: (state, action) => ({
      ...state,
      SuperAdminCompanyCountIDreducer: {
        ...state.SuperAdminCompanyCountIDreducer,
        loading: false,
        success: true,
        DamIdCompanydatalist: action.payload
      }
    }),

    ADMIN_DAM_DATA_SUCCESS: (state, action) => ({
      ...state,
      AdminDaminsideData: {
        ...state.AdminDaminsideData,
        loading: false,
        success: true,
        InsideData: action.payload
      }
    }),

    ADMIN_COLLECTION_DAM_FILTER_SUCCESS: (state, action) => ({
      ...state,
      AdminCollectionDamFilter: {
        ...state.AdminCollectionDamFilter,
        loading: false,
        success: true,
        CollectionDamFilter: action.payload
      }
    }),

    ADMIN_COLLECTION_DAM_SEARCH_SUCCESS: (state, action) => ({
      ...state,
      AdminCollectionsearchFilter: {
        ...state.AdminCollectionsearchFilter,
        loading: false,
        success: true,
        CollectionDamSearch: action.payload
      }
    }),

    ADMIN_COLLECTION_DAM_COUNT_SUCCESS: (state, action) => ({
      ...state,
      AdminCollectioncountFilter: {
        ...state.AdminCollectioncountFilter,
        loading: false,
        success: true,
        CollectionDamCount: action.payload
      }
    }),
    MEMBER_ADMIN_COMPANY_LIST_SUCCESS: (state, action) => ({
      ...state,
      memberAdminCompanyListReducer: {
        loading: false,
        success: true,
        memberCompanyAdmin: action.payload,
        agecyIdCompany: action.payload?.[0].agency,
        agecyNameCompany: action.payload[0].company__name
      }
    }),

    CLEAR_ADMIN_MEDIA: () => ({
      ...initialState
    })
  }
});

export const {
  SET_ADMIN_MEDIA_LOADING,
  ADMIN_DAM_SHARE_SUCCESS,
  ADMIN_DAM_ID_SUCCESS,
  ADMIN_DAM_IMAGES_SUCCESS,
  ADMIN_DAM_ROOT_LIST_LOADER,
  ADMIN_DAM_ROOT_LIST_SUCCESS,
  ADMIN_DAM_ID_LIST_SUCCESS,
  ADMIN_DAM_ID_IMAGES_SUCCESS,
  ADMIN_DAM_DOCUMENTS_SUCCESS,
  ADMIN_DAM_COLLECTION_ID_LIST_SUCCESS,
  ADMIN_DAM_LIST_POST_SUCCESS,
  ADMIN_DAM_PARENT_LIST_POST_SUCCESS,
  ADMIN_DAM_POST_COLLECTION_SUCCESS,
  ADMIN_DAM_LIST_COLLECTION_SUCCESS,
  ADMIN_DAM_DETAILS_SUCCESS,
  ADMIN_DAM_DELETE_SUCCESS,
  ADMIN_DAM_ROOT_IMAGES_SUCCESS,
  ADMIN_DAM_ROOT_DOCUMENTS_SUCCESS,
  ADMIN_DAM_COLLECTION_LIST_SUCCESS,
  ADMIN_DAM_Favourite_SUCCESS,
  ADMIN_DAM_TITLE_SUCCESS,
  ADMIN_COLLECTION_DELETE_LOADER,
  ADMIN_COLLECTION_DELETE_SUCCESS,
  ADMIN_COLLECTION_VIEW_SUCCESS,
  ADMIN_COLLECTION_FILES_POST_SUCCESS,
  ADMIN_MASS_TAGS_POST_SUCCESS,
  ADMIN_MOVE_FILES_POST_SUCCESS,
  ADMIN_DAM_MOVE_SUCCESS,
  ADMIN_DAM_ROOT_MOVE_SUCCESS,
  ADMIN_DAM_ROOT_COPY_SUCCESS,
  ADMIN_DAM_RENAME_SUCCESS,
  ADMIN_DAM_COLLECTION_COPY_SUCCESS,
  ADMIN_DAM_COLLECTION_COPY_ID_SUCCESS,
  ADMIN_DAM_COLLECTION_MULTIPLE_SUCCESS,
  ADMIN_DAM_MOVE_MULTIPLE_SUCCESS,
  ADMIN_DAM_SEARCH_SUCCESS,
  ADMIN_DAM_SEARCH1_SUCCESS,
  ADMIN_DAM_MOVE_COLLECTION_SUCCESS,
  ADMIN_DAM_VIDEOS_SUCCESS,
  ADMIN_DAM_FAVOURATE_FILTER_ID_SUCCESS,
  ADMIN_DAM_FAVOURATE_FILTER_SUCCESS,
  ADMIN_DAM_FAVOURATE_FILTER_COUNT_LOADER,
  ADMIN_DAM_FAVOURATE_FILTER_COUNT_SUCCESS,
  ADMIN_DAM_FAVOURATE_FILTER_COUNT_ID_SUCCESS,
  ADMIN_DAM_MostUsed_SUCCESS,
  ADMIN_DAM_PARENT_FILTER_SUCCESS,
  ADMIN_DAM_Company_SUCCESS,
  ADMIN_DAM_Company_ID_SUCCESS,
  ADMIN_DAM_Company_COUNT_SUCCESS,
  ADMIN_DAM_Company_COUNT_ID_SUCCESS,
  ADMIN_DAM_DATA_SUCCESS,
  ADMIN_COLLECTION_DAM_FILTER_SUCCESS,
  ADMIN_COLLECTION_DAM_SEARCH_SUCCESS,
  ADMIN_COLLECTION_DAM_COUNT_SUCCESS,
  ADMIN_DAM_POST_ID_COLLECTION_SUCCESS,
  CLEAR_ADMIN_MEDIA,
  ADMIN_RESET_SHARE_SUCCESS,
  MEMBER_ADMIN_COMPANY_LIST_SUCCESS
} = adminMedia.actions;

export const SuperAdminCollectionviewreducer = (state: RootState) =>
  state.media.adminMedia.SuperAdminCollectionviewreducer;

export const SuperAdminDamFilterFavourateCountnew = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamFilterFavourateCount;

export const SuperAdminDamFilterFavourateCountIDnew = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamFilterFavourateCountID;

export const SuperAdminDamimageReducernew = (state: RootState) => state.media.adminMedia.SuperAdminDamimageReducer;

export const SuperAdminRootDamReducernew = (state: RootState) => state.media.adminMedia.SuperAdminRootDamReducer;

export const SuperAdminCollectionviewreducernew = (state: RootState) =>
  state.media.adminMedia.SuperAdminCollectionviewreducer.success;

export const SuperAdminDamIDCollectionReducernew = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamIDCollectionReducer;

export const SuperAdminDamCollectionReducern = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamCollectionReducer;

export const SuperAdminCompanyCountreducern = (state: RootState) =>
  state.media.adminMedia.SuperAdminCompanyCountreducer;

export const SuperAdminDamRootImages1 = (state: RootState) => state.media.adminMedia.SuperAdminDamRootImages;

export const SuperAdminDamRootDocuments = (state: RootState) => state.media.adminMedia.SuperAdminDamRootDocuments;

export const SuperAdminDamIDIMAGESReducer = (state: RootState) => state.media.adminMedia.SuperAdminDamIDIMAGESReducer;

export const SuperAdminDamIDDocumentsReducer = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamIDDocumentsReducer;

export const SuperAdminDamimageReducern = (state: RootState) =>
  state.media.adminMedia.SuperAdminDamimageReducer.success;

export const SuperAdminPostDamIdCollectionReducerSuccess = (state: RootState) =>
  state.media.adminMedia.SuperAdminPostDamIdCollectionReducer;

export const SuperAdmindamrenamereducerSUcces = (state: RootState) =>
  state.media.adminMedia.SuperAdmindamrenamereducer.success;

export const SuperAdminDamParentCollection = (state: RootState) => state.media.adminMedia.SuperAdminDamParentCollection;

export const SuperAdminDamIDReducer1 = (state: RootState) => state.media.adminMedia.SuperAdminDamIDReducer;

export const SuperAdminCompanyreducern = (state: RootState) => state.media.adminMedia.SuperAdminCompanyreducer;

export const CompanyCountIDreducern = (state: RootState) => state.media.adminMedia.SuperAdminCompanyCountIDreducer;

export const SuperAdminDamReducern = (state: RootState) => state.media.adminMedia.SuperAdminDamReducer;

export const AdminDaminsideData = (state: RootState) => state.media.adminMedia.AdminDaminsideData;

export const SuperAdminCompanyIDreducer = (state: RootState) => state.media.adminMedia.SuperAdminCompanyIDreducer;

export const AdminCollectionDamFilter = (state: RootState) => state.media.adminMedia.AdminCollectionDamFilter;

export const AdminCollectioncountFilter = (state: RootState) => state.media.adminMedia.AdminCollectioncountFilter;

export const AdminCollectionsearchFilter = (state: RootState) => state.media.adminMedia.AdminCollectionsearchFilter;

export const MemberAdminCompanyListData = (state: RootState) => state.media.adminMedia.memberAdminCompanyListReducer;
