import { createSlice } from '@reduxjs/toolkit';
import { NDA_DATA_TYPE } from 'helper/types/profile/profileTypes';
import { RootState } from 'redux/rootReducer';

const initialState: NDA_DATA_TYPE = {
  userNDAList: {
    loading: false,
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  }
};

export const userNDASlice = createSlice({
  name: 'ndaList',
  initialState,
  reducers: {
    SET_NDA_LOADING: (state, action) => ({
      ...state,
      userNDAList: {
        ...state.userNDAList,
        loading: action.payload
      }
    }),
    SET_NDA_DATA: (state, action) => ({
      ...state,
      userNDAList: {
        ...state.userNDAList,
        data: action.payload
      }
    })
  }
});

export const { SET_NDA_LOADING, SET_NDA_DATA } = userNDASlice.actions;

export const GET_USER_NDA_LIST = (state: RootState) => state.profile.nda.userNDAList;
