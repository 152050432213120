import { Suspense, lazy } from 'react';
import './helper/config/momentConfig';

const RouteApp = lazy(() => import('./RouteApp'));

const App = () => {
  return (
    <Suspense fallback={''}>
      <RouteApp />
    </Suspense>
  );
};

export default App;
