import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  loading: false,
  templateList: {
    loading: false,
    data: {
      count: 0,
      next: null,
      prev: null,
      results: []
    }
  },
  templateDetails: {
    loading: false,
    details: null
  },
  templateActivityDetails: {
    loading: false,
    details: null
  },
  templateProposals: {
    loading: false,
    details: null
  },
  templateProposalDetails: {
    loading: false,
    details: null
  }
};

export const utmTemplatesSlice = createSlice({
  name: 'utmTemplates',
  initialState,
  reducers: {
    SET_UTM_TEMPLATES_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_UTM_TEMPLATES_DATA_LOADING: (state, action) => ({
      ...state,
      templateList: {
        ...state.templateList,
        loading: action.payload
      }
    }),

    SET_UTM_TEMPLATES_DATA: (state, action) => ({
      ...state,
      templateList: {
        ...state.templateList,
        data: action.payload
      }
    }),

    SET_UTM_TEMPLATES_DETAILS_LOADING: (state, action) => ({
      ...state,
      templateDetails: {
        ...state.templateDetails,
        loading: action.payload
      }
    }),

    SET_UTM_TEMPLATES_DETAILS: (state, action) => ({
      ...state,
      templateDetails: {
        ...state.templateDetails,
        details: action.payload
      }
    }),

    SET_UTM_TEMPLATES_ACTIVITY_LOADING: (state, action) => ({
      ...state,
      templateActivityDetails: {
        ...state.templateActivityDetails,
        loading: action.payload
      }
    }),

    SET_UTM_TEMPLATE_ACTIVITY: (state, action) => ({
      ...state,
      templateActivityDetails: {
        ...state.templateActivityDetails,
        details: action.payload
      }
    }),

    SET_UTM_TEMPLATES_PROPOSALS_LOADING: (state, action) => ({
      ...state,
      templateProposals: {
        ...state.templateProposals,
        loading: action.payload
      }
    }),

    SET_UTM_TEMPLATE_PROPOSALS: (state, action) => ({
      ...state,
      templateProposals: {
        ...state.templateProposals,
        details: action.payload
      }
    }),

    SET_UTM_TEMPLATES_PROPOSAL_DETAILS_LOADING: (state, action) => ({
      ...state,
      templateProposalDetails: {
        ...state.templateProposalDetails,
        loading: action.payload
      }
    }),

    SET_UTM_TEMPLATE_PROPOSAL_DETAILS: (state, action) => ({
      ...state,
      templateProposalDetails: {
        ...state.templateProposalDetails,
        details: action.payload
      }
    }),

    CLEAR_UTM_TEMPLATES: () => ({
      ...initialState
    })
  }
});

export const {
  SET_UTM_TEMPLATES_LOADING,
  SET_UTM_TEMPLATES_DATA_LOADING,
  SET_UTM_TEMPLATES_DATA,
  CLEAR_UTM_TEMPLATES,
  SET_UTM_TEMPLATES_DETAILS_LOADING,
  SET_UTM_TEMPLATES_DETAILS,
  SET_UTM_TEMPLATE_ACTIVITY,
  SET_UTM_TEMPLATE_PROPOSALS,
  SET_UTM_TEMPLATES_PROPOSALS_LOADING,
  SET_UTM_TEMPLATES_PROPOSAL_DETAILS_LOADING,
  SET_UTM_TEMPLATE_PROPOSAL_DETAILS
} = utmTemplatesSlice.actions;

export const UTM_TEMPLATES_DATA = (state: RootState) => state.utmTemplates.templateList;

export const UTM_TEMPLATES_DETAILS = (state: RootState) => state.utmTemplates.templateDetails;

export const UTM_TEMPLATES = (state: RootState) => state.utmTemplates;

export const UTM_TEMPLATES_LOADING = (state: RootState) => state.utmTemplates.loading;

export const GET_UTM_ACTIVITY_DETAIL_DATA = (state: RootState) => state.utmTemplates.templateActivityDetails;

export const GET_UTM_PROPOSALS = (state: RootState) => state.utmTemplates.templateProposals;

export const GET_UTM_PROPOSAL_DETAILS = (state: RootState) => state.utmTemplates.templateProposalDetails;
