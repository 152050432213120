import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  loading: false,
  communityActivityLogsList: {
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  },
  communityActivityLogDetails: {
    data: {}
  }
};

export const communityActivityLogsSlice = createSlice({
  name: 'communityActivityLogs',
  initialState,
  reducers: {
    SET_COMMUNITY_ACTIVITY_LOGS_LOADING: (state, action) => ({
      ...state,
      loading: action.payload
    }),

    SET_COMMUNITY_ACTIVITY_LOGS_DATA: (state, action) => ({
      ...state,
      communityActivityLogsList: {
        ...state.communityActivityLogsList,
        data: action.payload
      }
    }),

    SET_COMMUNITY_ACTIVITY_LOG_DETAILS_DATA: (state, action) => ({
      ...state,
      communityActivityLogDetails: {
        ...state.communityActivityLogDetails,
        data: action.payload
      }
    })
  }
});

export const {
  SET_COMMUNITY_ACTIVITY_LOGS_LOADING,
  SET_COMMUNITY_ACTIVITY_LOGS_DATA,
  SET_COMMUNITY_ACTIVITY_LOG_DETAILS_DATA
} = communityActivityLogsSlice.actions;

export const COMMUNITY_ACTIVITY_LOGS_DATA = (state: RootState) => state.activityLogs.communityActivityLogs;
