import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  myTasksList: {
    loading: false,
    data: {
      count: 0,
      next: null,
      prev: null,
      results: []
    }
  },
  myTasksDetails: {
    loading: false,
    details: null
  }
};

export const myTasksSlice = createSlice({
  name: 'myTasks',
  initialState,
  reducers: {
    SET_MY_TASKS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_MY_TASKS_LIST_LOADING: (state, action) => ({
      ...state,
      myTasksList: {
        ...state.myTasksList,
        loading: action.payload
      }
    }),

    SET_MY_TASKS_LIST_DATA: (state, action) => ({
      ...state,
      myTasksList: {
        ...state.myTasksList,
        data: action.payload
      }
    }),

    SET_MY_TASKS_DETAILS_LOADING: (state, action) => ({
      ...state,
      myTasksDetails: {
        ...state.myTasksDetails,
        loading: action.payload
      }
    }),

    SET_MY_TASKS_DETAILS_DATA: (state, action) => ({
      ...state,
      myTasksDetails: {
        ...state.myTasksDetails,
        details: action.payload
      }
    }),

    CLEAR_MY_TASK_FILTER: () => ({
      ...initialState
    })
  }
});

export const {
  SET_MY_TASKS_LOADING,
  SET_MY_TASKS_LIST_LOADING,
  SET_MY_TASKS_LIST_DATA,
  CLEAR_MY_TASK_FILTER,
  SET_MY_TASKS_DETAILS_LOADING,
  SET_MY_TASKS_DETAILS_DATA
} = myTasksSlice.actions;

export const MY_TASKS_LIST = (state: RootState) => state.myTasks.myTasksList.data;

export const MY_TASKS_LIST_LOADING = (state: RootState) => state.myTasks.myTasksList.loading;

export const MY_TASKS_DETAILS = (state: RootState) => state.myTasks.myTasksDetails.details;

export const MY_TASKS_DETAILS_LOADING = (state: RootState) => state.myTasks.myTasksDetails.loading;
