import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  loading: false,
  agencyInProgressJobsList: {
    loading: false,
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  }
};

export const inProgressJobsListSlice = createSlice({
  name: 'inProgressJobsList',
  initialState,
  reducers: {
    SET_IN_PROGRESS_JOBS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_AGENCY_IN_PROGRESS_JOBS_LIST_LOADING: (state, action) => ({
      ...state,
      agencyInProgressJobsList: {
        ...state.agencyInProgressJobsList,
        loading: action.payload
      }
    }),

    SET_AGENCY_IN_PROGRESS_JOBS_DATA: (state, action) => ({
      ...state,
      agencyInProgressJobsList: {
        ...state.agencyInProgressJobsList,
        data: action.payload
      }
    }),

    CLEAR_IN_PROGRESS_JOBS: () => ({
      ...initialState
    })
  }
});

export const {
  SET_IN_PROGRESS_JOBS_LOADING,
  SET_AGENCY_IN_PROGRESS_JOBS_LIST_LOADING,
  SET_AGENCY_IN_PROGRESS_JOBS_DATA,
  CLEAR_IN_PROGRESS_JOBS
} = inProgressJobsListSlice.actions;

export const AGENCY_IN_PROGRESS_JOBS_DATA = (state: RootState) =>
  state.homePage.inProgressJobsList.agencyInProgressJobsList;
