import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  DamShareReducer: { loading: false, success: true, shareDAM: null },
  DamReducer: { loading: false, success: true, DamData: null },
  DamimageReducer: { loading: false, success: true, DamImageData: null },
  RootDamReducer: { loading: false, success: true, RootDamData: null },
  DamIDReducer: { loading: false, success: true, DamData1: null },
  DamIDIMAGESReducer: { loading: false, success: true, DamDataImages: null },
  DamIDCollectionReducer: { loading: false, success: true, DamDataCollectionId: null },
  PostDamReducer: { loading: false, success: true, DamPostData: null },
  PostDamParentReducer: { loading: false, success: true, DamPostParentData: null },
  PostDamCollectionReducer: { loading: false, success: true, DamPostCollection: null },
  PostDamIdCollectionReducer: { loading: false, success: true, DamPostCollectionId: null },
  DamCollectionReducer: { loading: false, movecollection: true, DamPostCollectionData: null },
  DamDetailsReducer: { loading: false, success: true, DamDetails: {} },
  DamDeleteReducer: { loading: false, success: false },
  DamRootImages: { loading: false, success: true, DamRootImages: null },
  DamParentCollection: { loading: false, success: true, DamCollectionParentImages: null },
  Favourite: { loading: false, movecollection: false, FavouriteData: null },
  Titlereducer: { loading: false, success: false },
  CollectionDelete: { loading: false, success: true, DeletecollectionData: null },
  Collectionviewreducer: { loading: false, success: true, Collectionviewdata: null },
  Collectionfilespostreducer: { loading: false, success: true, Collectionfilesviewdata: null },
  DamMovepostreducer: { loading: false, success: true, Movedata: null },
  damfoldermovereducer: { loading: false, success: true, Dammovefolder: null },
  damrootfoldermovereducer: { loading: false, success: true, DamROOTmovefolder: null },
  damcopyreducer: { loading: false, success: true, DamROOTCopyfolder: null },
  damrenamereducer: { loading: false, success: true, DamRenamefolder: null },
  damcollectionmovereducer: { loading: false, success: true, Damcollectioncopyfolder: null },
  damcollectionIDmovereducer: { loading: false, success: true, Damcollectioncopyfolder1: null },
  DamcollectionMultipleDelete: { loading: false, success: true, DamMultipleDelete: null },
  DamMultipleMove: { loading: false, success: true, DamMultipleMove: null },
  DamSearch: { loading: false, success: true, DamSearch1: null },
  DamSearchfolder: { loading: false, success: true, DamSearchnew: null },
  DamInsideMove: { loading: false, success: true, DamCollectioninside: null },
  DamVideo: { loading: false, success: true, DamVideo: null },
  DamFilterFavourateID: { loading: false, success: true, DamFilterFavourateId: null },
  DamFilterFavourate: { loading: false, success: true, DamFiltering: null },
  DamFilterFavourateCount: { loading: false, success: true, DamCountData: null },
  DamFilterFavourateCountID: { loading: false, success: true, DamCountDataid: null },
  listAllMostUsed: { loading: false, success: true, Dammostused: null },
  AllParentFilter: { loading: false, success: true, DamParentFilter: null },
  Companyreducer: { loading: false, success: true, DamCompany: null },
  CompanyIDreducer: { loading: false, success: true, DamIdCompany: null },
  CompanyCountreducer: { loading: false, success: true, DamIdCompanylist: null },
  CompanyCountIDreducer: { loading: false, success: true, DamIdCompanydatalist: null },
  DaminsideData: { loading: false, success: true, InsideData: null },
  CollectionDamSearch: { loading: false, success: true, CollectionDamSearch: null },
  CollectionDamFilter: { loading: false, success: true, CollectionDamFilter: null },
  CollectionDamCount: { loading: false, success: true, CollectionDamCount: null },
  CollectionDamIDFilter: { loading: false, success: true, CollectionDamIDFilter: null }
};

export const agencyMedia = createSlice({
  name: 'agencyMedia',
  initialState,
  reducers: {
    SET_AGENCY_MEDIA_LOADING: (state, action) => {
      state.loading = action.payload;
    },
    CLEAR_ADMIN_MEDIA: () => ({
      ...initialState
    }),
    DAM_SHARE_SUCCESS: (state, action) => ({
      ...state,
      DamShareReducer: {
        ...state.DamShareReducer,
        loading: false,
        success: true,
        shareDAM: action.payload
      }
    }),
    DAM_ID_SUCCESS: (state, action) => ({
      ...state,
      DamReducer: {
        ...state.DamReducer,
        loading: false,
        success: true,
        DamData: action.payload
      }
    }),
    DAM_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      DamimageReducer: {
        ...state.DamimageReducer,
        loading: false,
        success: true,
        DamImageData: action.payload
      }
    }),
    DAM_ROOT_LIST_LOADER: (state, action) => ({
      ...state,
      RootDamReducer: {
        ...state.RootDamReducer,
        loading: action.payload
      }
    }),
    DAM_ROOT_LIST_SUCCESS: (state, action) => ({
      ...state,
      RootDamReducer: {
        ...state.RootDamReducer,
        loading: false,
        success: true,
        RootDamData: action.payload
      }
    }),
    DAM_ID_LIST_SUCCESS: (state, action) => ({
      ...state,
      DamIDReducer: {
        ...state.DamIDReducer,
        loading: false,
        success: true,
        DamData1: action.payload
      }
    }),
    DAM_ID_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      DamIDIMAGESReducer: {
        ...state.DamIDIMAGESReducer,
        loading: false,
        success: true,
        DamDataImages: action.payload
      }
    }),
    DAM_COLLECTION_ID_LIST_SUCCESS: (state, action) => ({
      ...state,
      DamIDCollectionReducer: {
        ...state.DamIDCollectionReducer,
        loading: false,
        success: true,
        DamDataCollectionId: action.payload
      }
    }),
    DAM_LIST_POST_SUCCESS: (state, action) => ({
      ...state,
      PostDamReducer: {
        ...state.PostDamReducer,
        loading: false,
        success: true,
        DamPostData: action.payload
      }
    }),
    DAM_PARENT_LIST_POST_SUCCESS: (state, action) => ({
      ...state,
      PostDamParentReducer: {
        ...state.PostDamParentReducer,
        loading: false,
        success: true,
        DamPostParentData: action.payload
      }
    }),
    DAM_POST_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      PostDamCollectionReducer: {
        ...state.PostDamCollectionReducer,
        loading: false,
        movecollection: true,
        DamPostCollectionData: action.payload
      }
    }),
    DAM_POST_ID_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      PostDamIdCollectionReducer: {
        ...state.PostDamIdCollectionReducer,
        loading: false,
        success: true,
        DamPostCollectionId: action.payload
      }
    }),
    DAM_LIST_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      DamCollectionReducer: {
        ...state.DamCollectionReducer,
        loading: false,
        movecollection: true,
        DamPostCollectionData: action.payload
      }
    }),
    DAM_DETAILS_SUCCESS: (state, action) => ({
      ...state,
      DamDetailsReducer: {
        ...state.DamDetailsReducer,
        loading: false,
        success: true,
        DamDetails: action.payload
      }
    }),
    DAM_DELETE_SUCCESS: (state, action) => ({
      ...state,
      DamDeleteReducer: {
        ...state.DamDeleteReducer,
        loading: false,
        success: true
      }
    }),
    DAM_ROOT_IMAGES_SUCCESS: (state, action) => ({
      ...state,
      DamRootImages: {
        ...state.DamRootImages,
        loading: false,
        movecollection: true,
        DamRootImages: action.payload
      }
    }),
    DAM_COLLECTION_LIST_SUCCESS: (state, action) => ({
      ...state,
      DamParentCollection: {
        ...state.DamParentCollection,
        loading: false,
        movecollection: true,
        DamCollectionParentImages: action.payload
      }
    }),
    DAM_Favourite_SUCCESS: (state, action) => ({
      ...state,
      Favourite: {
        ...state.Favourite,
        loading: false,
        movecollection: true,
        FavouriteData: action.payload
      }
    }),
    DAM_TITLE_SUCCESS: (state, action) => ({
      ...state,
      Titlereducer: {
        loading: false,
        success: true
      }
    }),
    COLLECTION_DELETE_SUCCESS: (state, action) => ({
      ...state,
      CollectionDelete: {
        ...state.CollectionDelete,
        loading: false,
        success: true,
        DeletecollectionData: action.payload
      }
    }),
    COLLECTION_VIEW_SUCCESS: (state, action) => ({
      ...state,
      Collectionviewreducer: {
        ...state.Collectionviewreducer,
        loading: false,
        success: true,
        Collectionviewdata: action.payload
      }
    }),
    COLLECTION_FILES_POST_SUCCESS: (state, action) => ({
      ...state,
      Collectionfilespostreducer: {
        ...state.Collectionfilespostreducer,
        loading: false,
        success: true,
        Collectionfilesviewdata: action.payload
      }
    }),
    MOVE_FILES_POST_SUCCESS: (state, action) => ({
      ...state,
      DamMovepostreducer: {
        ...state.DamMovepostreducer,
        loading: false,
        success: true,
        Movedata: action.payload
      }
    }),
    DAM_MOVE_SUCCESS: (state, action) => ({
      ...state,
      damfoldermovereducer: {
        ...state.damfoldermovereducer,
        loading: false,
        success: true,
        Dammovefolder: action.payload
      }
    }),
    DAM_ROOT_MOVE_SUCCESS: (state, action) => ({
      ...state,
      damrootfoldermovereducer: {
        ...state.damrootfoldermovereducer,
        loading: false,
        success: true,
        DamROOTmovefolder: action.payload
      }
    }),
    DAM_ROOT_COPY_SUCCESS: (state, action) => ({
      ...state,
      damcopyreducer: {
        ...state.damcopyreducer,
        loading: false,
        success: true,
        DamROOTCopyfolder: action.payload
      }
    }),
    DAM_RENAME_SUCCESS: (state, action) => ({
      ...state,
      damrenamereducer: {
        ...state.damrenamereducer,
        loading: false,
        success: true,
        DamRenamefolder: action.payload
      }
    }),
    DAM_COLLECTION_COPY_SUCCESS: (state, action) => ({
      ...state,
      damcollectionmovereducer: {
        ...state.damcollectionmovereducer,
        loading: false,
        success: true,
        Damcollectioncopyfolder: action.payload
      }
    }),
    DAM_COLLECTION_COPY_ID_SUCCESS: (state, action) => ({
      ...state,
      damcollectionIDmovereducer: {
        ...state.damcollectionIDmovereducer,
        loading: false,
        success: true,
        Damcollectioncopyfolder1: action.payload
      }
    }),
    DAM_COLLECTION_MULTIPLE_SUCCESS: (state, action) => ({
      ...state,
      DamcollectionMultipleDelete: {
        ...state.DamcollectionMultipleDelete,
        loading: false,
        success: true,
        DamMultipleDelete: action.payload
      }
    }),
    DAM_MOVE_MULTIPLE_SUCCESS: (state, action) => ({
      ...state,
      DamMultipleMove: {
        ...state.DamMultipleMove,
        loading: false,
        success: true,
        DamMultipleMove: action.payload
      }
    }),
    DAM_SEARCH_SUCCESS: (state, action) => ({
      ...state,
      DamSearch: {
        ...state.DamSearch,
        loading: false,
        success: true,
        DamSearch1: action.payload
      }
    }),
    DAM_SEARCH1_SUCCESS: (state, action) => ({
      ...state,
      DamSearchfolder: {
        ...state.DamSearchfolder,
        loading: false,
        success: true,
        DamSearchnew: action.payload
      }
    }),
    DAM_MOVE_COLLECTION_SUCCESS: (state, action) => ({
      ...state,
      DamInsideMove: {
        ...state.DamInsideMove,
        loading: false,
        success: true,
        DamCollectioninside: action.payload
      }
    }),
    DAM_VIDEOS_SUCCESS: (state, action) => ({
      ...state,
      DamVideo: {
        ...state.DamVideo,
        loading: false,
        success: true,
        DamVideo: action.payload
      }
    }),
    DAM_FAVOURATE_FILTER_ID_SUCCESS: (state, action) => ({
      ...state,
      DamFilterFavourateID: {
        ...state.DamFilterFavourateID,
        loading: false,
        success: true,
        DamFilterFavourateId: action.payload
      }
    }),
    DAM_FAVOURATE_FILTER_SUCCESS: (state, action) => ({
      ...state,
      DamFilterFavourate: {
        ...state.DamFilterFavourate,
        loading: false,
        success: true,
        DamFiltering: action.payload
      }
    }),
    DAM_FAVOURATE_FILTER_COUNT_SUCCESS: (state, action) => ({
      ...state,
      DamFilterFavourateCount: {
        ...state.DamFilterFavourateCount,
        loading: false,
        success: true,
        DamCountData: action.payload
      }
    }),
    DAM_FAVOURATE_FILTER_COUNT_ID_SUCCESS: (state, action) => ({
      ...state,
      DamFilterFavourateCountID: {
        ...state.DamFilterFavourateCountID,
        loading: false,
        success: true,
        DamCountDataid: action.payload
      }
    }),
    DAM_MostUsed_SUCCESS: (state, action) => ({
      ...state,
      listAllMostUsed: {
        ...state.listAllMostUsed,
        loading: false,
        success: true,
        Dammostused: action.payload
      }
    }),
    DAM_PARENT_FILTER_SUCCESS: (state, action) => ({
      ...state,
      AllParentFilter: {
        ...state.AllParentFilter,
        loading: false,
        success: true,
        DamParentFilter: action.payload
      }
    }),
    DAM_Company_SUCCESS: (state, action) => ({
      ...state,
      Companyreducer: {
        ...state.Companyreducer,
        loading: false,
        success: true,
        DamCompany: action.payload
      }
    }),
    DAM_Company_ID_SUCCESS: (state, action) => ({
      ...state,
      CompanyIDreducer: {
        ...state.CompanyIDreducer,
        loading: false,
        success: true,
        DamIdCompany: action.payload
      }
    }),
    DAM_Company_COUNT_SUCCESS: (state, action) => ({
      ...state,
      CompanyCountreducer: {
        ...state.CompanyCountreducer,
        loading: false,
        success: true,
        DamIdCompanylist: action.payload
      }
    }),
    DAM_Company_COUNT_ID_SUCCESS: (state, action) => ({
      ...state,
      CompanyCountIDreducer: {
        ...state.CompanyCountIDreducer,
        loading: false,
        success: true,
        DamIdCompanydatalist: action.payload
      }
    }),
    DAM_DATA_SUCCESS: (state, action) => ({
      ...state,
      DaminsideData: {
        ...state.DaminsideData,
        loading: false,
        success: true,
        InsideData: action.payload
      }
    }),
    COLLECTION_DAM_SEARCH_SUCCESS: (state, action) => ({
      ...state,
      CollectionDamSearch: {
        ...state.CollectionDamSearch,
        loading: false,
        success: true,
        CollectionDamSearch: action.payload
      }
    }),
    COLLECTION_DAM_FILTER_SUCCESS: (state, action) => ({
      ...state,
      CollectionDamFilter: {
        ...state.CollectionDamFilter,
        loading: false,
        success: true,
        CollectionDamFilter: action.payload
      }
    }),
    COLLECTION_DAM_COUNT_SUCCESS: (state, action) => ({
      ...state,
      CollectionDamCount: {
        ...state.CollectionDamCount,
        loading: false,
        success: true,
        CollectionDamCount: action.payload
      }
    }),
    COLLECTION_DAM_FILTER_ID_SUCCESS: (state, action) => ({
      ...state,
      CollectionDamIDFilter: {
        ...state.CollectionDamIDFilter,
        loading: false,
        success: true,
        CollectionDamIdFilter: action.payload
      }
    })
  }
});

export const {
  SET_AGENCY_MEDIA_LOADING,
  CLEAR_ADMIN_MEDIA,
  DAM_SHARE_SUCCESS,
  DAM_ID_SUCCESS,
  DAM_IMAGES_SUCCESS,
  DAM_ROOT_LIST_LOADER,
  DAM_ROOT_LIST_SUCCESS,
  DAM_ID_LIST_SUCCESS,
  DAM_ID_IMAGES_SUCCESS,
  DAM_COLLECTION_ID_LIST_SUCCESS,
  DAM_LIST_POST_SUCCESS,
  DAM_PARENT_LIST_POST_SUCCESS,
  DAM_POST_COLLECTION_SUCCESS,
  DAM_LIST_COLLECTION_SUCCESS,
  DAM_Favourite_SUCCESS,
  DAM_TITLE_SUCCESS,
  COLLECTION_DELETE_SUCCESS,
  COLLECTION_VIEW_SUCCESS,
  COLLECTION_FILES_POST_SUCCESS,
  MOVE_FILES_POST_SUCCESS,
  DAM_MOVE_SUCCESS,
  DAM_ROOT_MOVE_SUCCESS,
  DAM_ROOT_COPY_SUCCESS,
  DAM_RENAME_SUCCESS,
  DAM_COLLECTION_COPY_SUCCESS,
  DAM_COLLECTION_COPY_ID_SUCCESS,
  DAM_MOVE_MULTIPLE_SUCCESS,
  DAM_SEARCH_SUCCESS,
  DAM_SEARCH1_SUCCESS,
  DAM_MOVE_COLLECTION_SUCCESS,
  DAM_VIDEOS_SUCCESS,
  DAM_FAVOURATE_FILTER_ID_SUCCESS,
  DAM_FAVOURATE_FILTER_SUCCESS,
  DAM_FAVOURATE_FILTER_COUNT_SUCCESS,
  DAM_FAVOURATE_FILTER_COUNT_ID_SUCCESS,
  DAM_MostUsed_SUCCESS,
  DAM_PARENT_FILTER_SUCCESS,
  DAM_Company_SUCCESS,
  DAM_Company_ID_SUCCESS,
  DAM_Company_COUNT_SUCCESS,
  DAM_Company_COUNT_ID_SUCCESS,
  DAM_DATA_SUCCESS,
  COLLECTION_DAM_SEARCH_SUCCESS,
  COLLECTION_DAM_FILTER_SUCCESS,
  COLLECTION_DAM_COUNT_SUCCESS,
  COLLECTION_DAM_FILTER_ID_SUCCESS,
  DAM_DELETE_SUCCESS,
  DAM_DETAILS_SUCCESS,
  DAM_POST_ID_COLLECTION_SUCCESS,
  DAM_COLLECTION_LIST_SUCCESS,
  DAM_COLLECTION_MULTIPLE_SUCCESS,
  DAM_ROOT_IMAGES_SUCCESS
} = agencyMedia.actions;
