import { ROLES, UserLevel } from 'helper/config';
import { SidebarRoutesTypes } from 'helper/types';

const userData = () => JSON.parse(localStorage.getItem('userData')) ?? '';

export const SYSTEM: Readonly<{ LOGIN: string; HOME: string }> = Object.freeze({
  LOGIN: '/',
  HOME: '/'
});

export const MAIN_ROUTE: Readonly<{ HOME: string; HOME_DASH: string }> = Object.freeze({
  HOME: `${SYSTEM.HOME}`,
  HOME_DASH: `${SYSTEM.HOME}home`
});

// Authentication Route
export const PUBLIC_ROUTE: Readonly<{
  LOGIN: string;
  SIGNUP: string;
  VERIFY_EMAIL: string;
  THANK_YOU: string;
  FORGOT_PASSWORD: string;
  RESET_PASSWORD: string;
  INVITE_ACCEPT: string;
  INVITE_REJECT: string;
  SIGNUP_INVITE: string;
  INVITE_REGISTER: string;
  GUEST_JOB: string;
  GUEST_JOB_COPY_LINK: string;
  EMAIL_TEMPLATE: string;
  PUBLIC_INTAKE_FORM: string;
}> = Object.freeze({
  LOGIN: `${SYSTEM.LOGIN}`,
  SIGNUP: `${SYSTEM.HOME}signup`,
  VERIFY_EMAIL: `${SYSTEM.HOME}verify-email/:token/:uid`,
  THANK_YOU: `${SYSTEM.HOME}thank-you`,
  FORGOT_PASSWORD: `${SYSTEM.HOME}forgot-password`,
  RESET_PASSWORD: `${SYSTEM.HOME}reset-password/:ResetpasswordId/:userId/`,
  INVITE_ACCEPT: `${SYSTEM.HOME}invite-accept/:decodeId/:accept_invite_encode/:exclusive_decode`,
  INVITE_REJECT: `${SYSTEM.HOME}invite-reject/:decodeId/:accept_invite_encode/:exclusive_decode`,
  SIGNUP_INVITE: `${SYSTEM.HOME}signup-invite/:inviteId/:exclusive/:encodedEmail`,
  INVITE_REGISTER: `${SYSTEM.HOME}thank-you/:successPage`,
  GUEST_JOB: `${SYSTEM.HOME}guest-job/:jobId`,
  GUEST_JOB_COPY_LINK: `${SYSTEM.HOME}guest-job/:jobId/:linkCopy`,
  EMAIL_TEMPLATE: `${SYSTEM.HOME}email-template`,
  PUBLIC_INTAKE_FORM: `${SYSTEM.HOME}intake/:formName`
});

// Authentication Route
export const PAGE_ROUTE: Readonly<{
  HOME: string;
  UTM_TEMPALTES: string;
  WORKFLOW: string;
  MY_PROJECTS: string;
  APPROVALS: string;
  COMMUNITIES: string;
  CALENDAR: string;
  COMPANY_PROJECTS: string;
  INTAKE_FORMS: string;
  MEDIA: string;
  // MY_TASKS: string;
  MY_JOBS: string;
  DRAFT_JOBS: string;
  TEMPLATES: string;
  PROFILE: string;
  INVITE: string;
  REPORTING: string;
  ACTIVITY_LOGS: string;
  // INDUSTRIES: string;
  SKILLS: string;
  USERS: string;
  COMPANY: string;
  HELP: string;
}> = Object.freeze({
  HOME: `${MAIN_ROUTE.HOME_DASH}`,
  UTM_TEMPALTES: `${MAIN_ROUTE.HOME}utm-templates`,
  WORKFLOW: `${MAIN_ROUTE.HOME}workflow`,
  MY_PROJECTS: `${MAIN_ROUTE.HOME}projects`,
  APPROVALS: `${MAIN_ROUTE.HOME}approvals`,
  COMMUNITIES: `${MAIN_ROUTE.HOME}`,
  CALENDAR: `${MAIN_ROUTE.HOME}calendar`,
  COMPANY_PROJECTS: `${MAIN_ROUTE.HOME}company-projects`,
  INTAKE_FORMS: `${MAIN_ROUTE.HOME}intake`,
  MEDIA: `${MAIN_ROUTE.HOME}media`,
  // MY_TASKS: `${MAIN_ROUTE.HOME}my-tasks`,
  MY_JOBS: `${MAIN_ROUTE.HOME}jobs`,
  DRAFT_JOBS: `${MAIN_ROUTE.HOME}draft-jobs`,
  TEMPLATES: `${MAIN_ROUTE.HOME}templates-list`,
  PROFILE: `${MAIN_ROUTE.HOME}profile`,
  INVITE: `${MAIN_ROUTE.HOME}invite`,
  REPORTING: `${MAIN_ROUTE.HOME}reporting`,
  ACTIVITY_LOGS: `${MAIN_ROUTE.HOME}activity-logs`,
  // INDUSTRIES: `${MAIN_ROUTE.HOME}industries`,
  SKILLS: `${MAIN_ROUTE.HOME}skills-list`,
  USERS: `${MAIN_ROUTE.HOME}users-list`,
  COMPANY: `${MAIN_ROUTE.HOME}company`,
  HELP: `${MAIN_ROUTE.HOME}help`
});

// Workflow Route
export const WORKFLOW_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  HOME: `${PAGE_ROUTE.WORKFLOW}`,
  CREATE_WORKFLOW: `${PAGE_ROUTE.WORKFLOW}/*`
  // UPDATE_WORKFLOW: `${PAGE_ROUTE.WORKFLOW}/edit/:workflowId`,
});

// Companies Route
export const COMPANIES_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  COMPANY_PROJECTS_DETAILS: `${PAGE_ROUTE.COMPANY_PROJECTS}/:storyId`,
  TAGS: `${PAGE_ROUTE.COMPANY_PROJECTS}/tags`,
  COMMUNITY_SETTINGS: `${PAGE_ROUTE.COMPANY_PROJECTS}/community-settings`,
  PROGRAMS: `${PAGE_ROUTE.COMPANY_PROJECTS}/programs`,
  COPY_CODE: `${PAGE_ROUTE.COMPANY_PROJECTS}/copy-code`,
  CREATIVE_CODE: `${PAGE_ROUTE.COMPANY_PROJECTS}/creative-code`,
  AUDIENCE: `${PAGE_ROUTE.COMPANY_PROJECTS}/audience`
});

//IntakeForm Route
export const INTAKE_FORMS_ROUTE: Readonly<{
  CREATE_INTAKE_FORM: string;
  EDIT_INTAKE_FORM: string;
  VIEW_INTAKE_FORM: string;
  RESPONSE_INTAKE_FORM: string;
  PUBLIC_INTAKE_FORM: string;
  [key: string]: string;
}> = Object.freeze({
  CREATE_INTAKE_FORM: `${PAGE_ROUTE.INTAKE_FORMS}/create`,
  EDIT_INTAKE_FORM: `${PAGE_ROUTE.INTAKE_FORMS}/edit/:formName`,
  VIEW_INTAKE_FORM: `${PAGE_ROUTE.INTAKE_FORMS}/view/:formName`,
  RESPONSE_INTAKE_FORM: `${PAGE_ROUTE.INTAKE_FORMS}/responses/:formName`,
  PUBLIC_INTAKE_FORM: `${PAGE_ROUTE.INTAKE_FORMS}/:formName`
});

//Media Route
export const MEDIA_ROUTE: Readonly<{
  MEDIA_HOME: string;
  FOLDER_VIEW: string;
  TABLE_VIEW: string;
  TABLE_FOLDER_VIEW: string;
  ADMIN_MEDIA_COLLECTION_DATA: string;
  ADMIN_MEDIA_COLLECTION: string;
  TABLE_VIEW_ADMIN_MEDIA_COLLECTION: string;
  TABLE_VIEW_ADMIN_MEDIA_COLLECTION_ID: string;
  PUBLIC_VIEW: string;
  PUBLIC_TABLE_VIEW: string;
  PUBLIC_COLLECTION_VIEW: string;
  // MEDIA_COLLECTION: string;
  // MEDIA_COLLECTION_ID: string;
  // AGENCY_MEDIA: string;
}> = Object.freeze({
  MEDIA_HOME: `${PAGE_ROUTE.MEDIA}`,
  FOLDER_VIEW: `${PAGE_ROUTE.MEDIA}/:parentid`,
  TABLE_VIEW: `${PAGE_ROUTE.MEDIA}-datatable/`,
  TABLE_FOLDER_VIEW: `${PAGE_ROUTE.MEDIA}-datatable/:parentid`,

  ADMIN_MEDIA_COLLECTION_DATA: `${PAGE_ROUTE.MEDIA}-collectionData`,
  ADMIN_MEDIA_COLLECTION: `${PAGE_ROUTE.MEDIA}/:parentid/collection`,

  TABLE_VIEW_ADMIN_MEDIA_COLLECTION: `${PAGE_ROUTE.MEDIA}-tableview/collectionData`,
  TABLE_VIEW_ADMIN_MEDIA_COLLECTION_ID: `${PAGE_ROUTE.MEDIA}/:parentid/table-view-collection`,
  PUBLIC_VIEW: `${PAGE_ROUTE.MEDIA}/public/:parentid`,
  PUBLIC_TABLE_VIEW: `${PAGE_ROUTE.MEDIA}-datatable/public/:parentid`,
  PUBLIC_COLLECTION_VIEW: `${PAGE_ROUTE.MEDIA}/public/:parentid/collection`

  // MEDIA_COLLECTION: `${PAGE_ROUTE.MEDIA}Media/collection`,
  // MEDIA_COLLECTION_ID: `${PAGE_ROUTE.MEDIA}Media/:parentid/collection`,

  // AGENCY_MEDIA: `${PAGE_ROUTE.MEDIA}-agency`
});

// My Jobs Route
export const MY_JOBS_ROUTE: Readonly<{
  HOME: string;
  CREATE_MY_JOB: string;
  UPDATE_MY_JOB: string;
  MY_JOB_DETAILS: string;
  [key: string]: string;
}> = Object.freeze({
  HOME: `${PAGE_ROUTE.MY_JOBS}`,
  CREATE_MY_JOB: `${PAGE_ROUTE.MY_JOBS}/add`,
  UPDATE_MY_JOB: `${PAGE_ROUTE.MY_JOBS}/:jobId`,
  MY_JOB_DETAILS: `${PAGE_ROUTE.MY_JOBS}/details/:jobId`
});

// Draft Jobs Route
export const DRAFT_JOBS_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  HOME: `${PAGE_ROUTE.DRAFT_JOBS}`,
  CREATE_DRAFT_JOB: `${PAGE_ROUTE.DRAFT_JOBS}/add`,
  UPDATE_DRAFT_JOB: `${PAGE_ROUTE.DRAFT_JOBS}/:jobId`
});

// Templates Route
export const TEMPLATES_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  HOME: `${PAGE_ROUTE.TEMPLATES}`,
  UPDATE_TEMPLATE: `${PAGE_ROUTE.TEMPLATES}/:templateId`
});

//Activity logs Route
export const ACTIVITY_LOGS_ROUTE: Readonly<{
  ACTIVITY_LOG_DETAILS: string;
}> = Object.freeze({
  ACTIVITY_LOG_DETAILS: `${PAGE_ROUTE.ACTIVITY_LOGS}/:logId`
});

// Company Route
export const COMPANY_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  COMPANY_LIST_DETAILS: `${PAGE_ROUTE.COMPANY}/:companyId/:agencyId?`,
  CREATE_COMPANY: `${PAGE_ROUTE.COMPANY}/add`
});

// Help Route
export const HELP_ROUTE: Readonly<{
  [key: string]: string;
}> = Object.freeze({
  HOME: `${PAGE_ROUTE.HELP}`,
  CREATE_HELP: `${PAGE_ROUTE.HELP}/add`,
  VIEW_HELP: `${PAGE_ROUTE.HELP}/view-message/:helpChatId`
});

// // Industries Route
// export const INDUSTRIES_ROUTE: Readonly<{
//   [key: string]: string;
// }> = Object.freeze({
//   HOME: `${PAGE_ROUTE.INDUSTRIES}`
// });

// My Task Route
// export const MY_TASKS: Readonly<{
//   [key: string]: string;
// }> = Object.freeze({
//   HOME: `${PAGE_ROUTE.MY_TASKS}`,
//   VIEW_MY_TASK: `${PAGE_ROUTE.MY_TASKS}/view-my-task/:myTaskId`
// });

//UTM templates Route
export const UTM_TEMPLATES_ROUTE: Readonly<{
  UTM_TEMPLATE_DETAILS: string;
  CREATE_UTM_FORM: string;
  EDIT_UTM_FORM: string;
}> = Object.freeze({
  UTM_TEMPLATE_DETAILS: `${PAGE_ROUTE.UTM_TEMPALTES}/:utm_template_id`,
  CREATE_UTM_FORM: `${PAGE_ROUTE.UTM_TEMPALTES}/create`,
  EDIT_UTM_FORM: `${PAGE_ROUTE.UTM_TEMPALTES}/edit/:utm_template_id`
});

// Define Sidebar Route
export const SIDEBAR_ROUTES: SidebarRoutesTypes[] = [
  // Dashboard
  //Agency Sidebar
  {
    name: 'Home',
    path: PAGE_ROUTE.HOME,
    icon: 'HomeOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER, ROLES.CREATOR], // Empty means it has all role access,
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER, UserLevel.IN_HOUSE_DESIGNER]
  },
  {
    name: 'Workflow',
    path: WORKFLOW_ROUTE.HOME,
    icon: 'GridViewOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'My Projects',
    path: PAGE_ROUTE.MY_PROJECTS,
    icon: 'DescriptionOutlined',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER, ROLES.CREATOR],
    userLevel: [UserLevel.IN_HOUSE_DESIGNER, UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Approvals',
    path: PAGE_ROUTE.APPROVALS,
    icon: 'Rule',
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER, UserLevel.APPROVER]
  },
  {
    name: 'Communities',
    path: PAGE_ROUTE.COMMUNITIES,
    icon: 'AccountTreeOutlined',
    children: [
      {
        name: 'Company Projects',
        path: PAGE_ROUTE.COMPANY_PROJECTS,
        children: []
      },
      {
        name: 'Tags',
        path: COMPANIES_ROUTE.TAGS,
        children: []
      },
      {
        name: 'Community Settings',
        path: COMPANIES_ROUTE.COMMUNITY_SETTINGS,
        children: []
      },
      {
        name: 'Programs',
        path: COMPANIES_ROUTE.PROGRAMS,
        children: []
      },
      {
        name: 'Copy Code',
        path: COMPANIES_ROUTE.COPY_CODE,
        children: []
      },
      {
        name: 'Creative Code',
        path: COMPANIES_ROUTE.CREATIVE_CODE,
        children: []
      },
      {
        name: 'Audience',
        path: COMPANIES_ROUTE.AUDIENCE,
        children: []
      }
      // {
      //   title: "Stories Options",
      //   imgPath: "/img/tabsicon.png",
      //   path: "/company-project/stories-options",
      // },
    ],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Calendar',
    path: PAGE_ROUTE.CALENDAR,
    icon: 'EventOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Intake Forms',
    path: PAGE_ROUTE.INTAKE_FORMS,
    icon: 'AssignmentOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN, UserLevel.MARKETER]
  },
  {
    name: 'Media',
    path: PAGE_ROUTE.MEDIA,
    icon: 'PermMediaOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.IN_HOUSE_DESIGNER, UserLevel.MARKETER, UserLevel.ADMIN]
  },
  // {
  //   name: 'My Tasks',
  //   path: PAGE_ROUTE.MY_TASKS,
  //   icon: 'TaskAltOutlined',
  //   children: [],
  //   permission: [ROLES.ADMIN, ROLES.AGENCY],
  //   userLevel: []
  // },
  {
    name: 'Jobs',
    path: MY_JOBS_ROUTE.HOME,
    icon: 'BusinessCenterOutlined',
    children: [],
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    name: 'My Jobs',
    path: MY_JOBS_ROUTE.HOME,
    icon: 'BusinessCenterOutlined',
    children: [],
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.IN_HOUSE_DESIGNER, UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Draft Jobs',
    path: PAGE_ROUTE.DRAFT_JOBS,
    icon: 'EditCalendarOutlined',
    children: [],
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.ADMIN]
  },
  {
    name: 'Job Templates',
    path: TEMPLATES_ROUTE.HOME,
    icon: 'ReceiptLongOutlined',
    children: [],
    permission: [ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'UTM Template',
    path: PAGE_ROUTE.UTM_TEMPALTES,
    icon: 'ViewTimelineOutlined',
    children: [],
    permission: [ROLES.ADMIN, ROLES.AGENCY, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Available Jobs',
    path: MY_JOBS_ROUTE.HOME,
    icon: 'CardTravelOutlined',
    permission: [ROLES.CREATOR],
    userLevel: []
  },
  // {
  //   name: 'Industries',
  //   path: PAGE_ROUTE.INDUSTRIES,
  //   // icon: 'LocationCityOutlined',
  //   icon: 'ApartmentOutlined',
  //   children: [],
  //   permission: [ROLES.ADMIN],
  //   userLevel: []
  // },
  {
    name: 'Skills',
    path: PAGE_ROUTE.SKILLS,
    icon: 'PsychologyOutlined',
    children: [],
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    name: 'Users',
    path: PAGE_ROUTE.USERS,
    icon: 'Groups2Outlined',
    children: [],
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    name: 'Reporting',
    path: PAGE_ROUTE.REPORTING,
    icon: 'SummarizeOutlined',
    permission: [ROLES.AGENCY],
    userLevel: []
  },
  {
    name: 'Activity Logs',
    path: PAGE_ROUTE.ACTIVITY_LOGS,
    icon: 'SummarizeOutlined',
    permission: [ROLES.ADMIN],
    userLevel: []
  },
  {
    id: 'Companies',
    name: 'Companies',
    path: PAGE_ROUTE.COMPANY,
    icon: 'VillaOutlined',
    permission: [ROLES.AGENCY, ROLES.ADMIN, ROLES.MEMBER],
    userLevel: [UserLevel.MARKETER, UserLevel.ADMIN]
  },
  {
    name: 'Help',
    path: HELP_ROUTE.HOME,
    icon: 'HelpOutlineOutlined',
    children: [],
    permission: [],
    userLevel: []
  }

  // Agency and admin
  // {
  //   name: "Earnings",
  //   imgPath: "/img/Earnings_old.png",
  //   path: "/earnings",
  // },
  // { name: "Analytics", imgPath: "/img/Analytics.png", path: "/analytics" },
  // {
  //   name: "Transactions",
  //   imgPath: "/img/Transactions.png",
  //   path: "/transactions",
  // },

  // Admin
  // { name: "Levels", imgPath: "/img/lavel.png", path: "/levels/list" },
  // {
  //   name: "Categories",
  //   imgPath: "/img/category.png",
  //   path: "/categories/list",
  // },
  // { name: "Settings", imgPath: "/img/Settings.png", path: "/settings" },
];
