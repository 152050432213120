import { combineReducers } from 'redux';
import { damSlice } from './dam.slice';
import { mediaSlice } from './media.slice';
import { adminMedia } from './adminMedia.slice';
import { agencyMedia } from './agencyMedia.slice';

export const mediaReducer = combineReducers({
  dam: damSlice.reducer,
  media: mediaSlice.reducer,
  adminMedia: adminMedia.reducer,
  agencyMedia: agencyMedia.reducer
});
