import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';
import { CompanyListInitialsType } from 'helper/types/companyTab/companiesType';

const initialState: CompanyListInitialsType = {
  loading: false,
  allCompanyList: {
    loading: false,
    success: false,
    data: []
  },
  companyList: {
    loading: false,
    success: false,
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  },
  singleCompany: {
    loading: false,
    data: {}
  },
  memberAdminCompanyList: {
    loading: false,
    data: null
  }
};

export const companyTabSlice = createSlice({
  name: 'companyDataTab',
  initialState,
  reducers: {
    SET_COMPANY_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_ALL_COMPANY_LIST_LOADING: (state, action) => ({
      ...state,
      allCompanyList: {
        ...state.allCompanyList,
        loading: action.payload
      }
    }),

    SET_ALL_COMPANY_LIST_DATA: (state, action) => {
      return {
        ...state,
        allCompanyList: {
          ...state.allCompanyList,
          success: true,
          data: action.payload
        }
      };
    },

    SET_COMPANY_LIST_LOADING: (state, action) => ({
      ...state,
      companyList: {
        ...state.companyList,
        loading: action.payload
      }
    }),

    SET_COMPANY_LIST_DATA: (state, action) => {
      return {
        ...state,
        companyList: {
          ...state.companyList,
          success: true,
          data: action.payload
        }
      };
    },

    SET_SINGLE_COMPANY_LIST_LOADING: (state, action) => ({
      ...state,
      singleCompany: {
        ...state.singleCompany,
        loading: action.payload
      }
    }),

    SET_SINGLE_COMPANY_LIST_DATA: (state, action) => {
      return {
        ...state,
        singleCompany: {
          ...state.singleCompany,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_MEMBER_ADMIN_COMPANY_LIST_LOADING: (state, action) => ({
      ...state,
      memberAdminCompanyList: {
        ...state.memberAdminCompanyList,
        loading: action.payload
      }
    }),

    SET_MEMBER_ADMIN_COMPANY_LIST_DATA: (state, action) => {
      return {
        ...state,
        memberAdminCompanyList: {
          ...state.memberAdminCompanyList,
          data: action.payload
        }
      };
    }
  }
});

export const {
  SET_COMPANY_LOADING,
  SET_ALL_COMPANY_LIST_LOADING,
  SET_ALL_COMPANY_LIST_DATA,
  SET_COMPANY_LIST_LOADING,
  SET_COMPANY_LIST_DATA,
  SET_SINGLE_COMPANY_LIST_LOADING,
  SET_SINGLE_COMPANY_LIST_DATA,
  SET_MEMBER_ADMIN_COMPANY_LIST_LOADING,
  SET_MEMBER_ADMIN_COMPANY_LIST_DATA
} = companyTabSlice.actions;

export const ALL_COMPANY_LIST = (state: RootState) => state.companyTab.companyDataTab.allCompanyList;
export const COMPANY_LIST = (state: RootState) => state.companyTab.companyDataTab;
export const COMPANY_DETAILS = (state: RootState) => state.companyTab.companyDataTab.singleCompany;

export const MEMBER_ADMIN_COMPANY_DATA = (state: RootState) => state.companyTab.companyDataTab.memberAdminCompanyList;
