import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  intakeFormsList: {
    loading: false,
    data: {
      count: 0,
      next: null,
      prev: null,
      results: []
    }
  }
};

export const intakeFormsSlice = createSlice({
  name: 'intakeForms',
  initialState,
  reducers: {
    SET_INTAKE_FORMS_DATA_LOADING: (state, action) => ({
      ...state,
      intakeFormsList: {
        ...state.intakeFormsList,
        loading: action.payload
      }
    }),

    SET_INTAKE_FORMS_DATA: (state, action) => ({
      ...state,
      intakeFormsList: {
        ...state.intakeFormsList,
        data: action.payload
      }
    })
  }
});

export const { SET_INTAKE_FORMS_DATA_LOADING, SET_INTAKE_FORMS_DATA } = intakeFormsSlice.actions;

export const INTAKE_FORMS_DATA = (state: RootState) => state.intakeForms.intakeFormsList;
// export const INTAKE_FORM_FIELDS_DATA = (state: RootState) => state..deletedCommentData.commentData;
