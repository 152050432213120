import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  scheduleEventsList: {
    loading: false,
    data: null
  },
  scheduleEventData: {
    loading: false,
    data: null
  },
  scheduleEvents: [],
  response: {
    add: null,
    update: null,
    remove: null
  },
  date_selection: {
    start_date: '',
    end_date: ''
  }
};

export const scheduleEventsSlice = createSlice({
  name: 'scheduleEvents',
  initialState,
  reducers: {
    SET_SCHEDULE_EVENTS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_SCHEDULE_EVENTS_LIST_LOADING: (state, action) => ({
      ...state,
      scheduleEventsList: {
        ...state.scheduleEventsList,
        loading: action.payload
      }
    }),

    SET_SCHEDULE_EVENTS_LIST_DATA: (state, action) => {
      return {
        ...state,
        scheduleEventsList: {
          ...state.scheduleEventsList,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_SCHEDULE_EVENT_DATA_LOADING: (state, action) => ({
      ...state,
      scheduleEventData: {
        ...state.scheduleEventData,
        loading: action.payload
      }
    }),

    SET_SCHEDULE_EVENT_DATA: (state, action) => {
      return {
        ...state,
        scheduleEventData: {
          ...state.scheduleEventData,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_SCHEDULE_EVENTS: (state, action) => {
      return {
        ...state,
        scheduleEvents: action.payload
      };
    },

    SET_SCHEDULE_EVENT_ADD: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: action.payload,
        update: null,
        remove: null
      }
      // lists: action.payload
    }),
    SET_SCHEDULE_EVENT_UPDATE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: action.payload,
        remove: null
      }
    }),
    SET_SCHEDULE_EVENT_DELETE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: null,
        remove: action.payload
      }
    }),

    SET_SCHEDULE_EVENT_SELECTED_DATE: (state, action) =>
      // console.log({ state, action }),
      ({
        ...state,
        date_selection: {
          ...state.date_selection,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date
        }
      })
  }
});

export const {
  SET_SCHEDULE_EVENTS_LOADING,
  SET_SCHEDULE_EVENTS_LIST_LOADING,
  SET_SCHEDULE_EVENTS_LIST_DATA,
  SET_SCHEDULE_EVENT_DATA_LOADING,
  SET_SCHEDULE_EVENT_DATA,
  SET_SCHEDULE_EVENTS,
  SET_SCHEDULE_EVENT_ADD,
  SET_SCHEDULE_EVENT_UPDATE,
  SET_SCHEDULE_EVENT_DELETE,
  SET_SCHEDULE_EVENT_SELECTED_DATE
} = scheduleEventsSlice.actions;

export const SCHEDULE_EVENTS_LOADING = (state: RootState) => state.ScheduleEvent.loading;

export const SCHEDULE_EVENTS_LIST = (state: RootState) => state.ScheduleEvent.scheduleEventsList.data;

export const SCHEDULE_EVENTS_LIST_LOADING = (state: RootState) => state.ScheduleEvent.scheduleEventsList.loading;

export const GET_CALENDAR_SCHEDULE_EVENTS = (state: RootState) => state.ScheduleEvent.scheduleEvents;

export const SCHEDULE_EVENT_DATA = (state: RootState) => state.ScheduleEvent.scheduleEventData.data;

export const SCHEDULE_EVENT_DATA_LOADING = (state: RootState) => state.ScheduleEvent.scheduleEventData.loading;

export const GET_SCHEDULE_RESPONSES = (state: RootState) => state.ScheduleEvent.response;

export const GET_DATE_SELECTION_DATA = (state: RootState) => state.ScheduleEvent.date_selection;
