import { combineReducers } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

const initialState = {
  loading: false,
  allMediaData: null,
  recentFiles: {
    loading: false,
    data: null
  },
  folders: {
    loading: false,
    data: null
  },
  files: {
    loading: false,
    data: null
  },
  collections: {
    loading: false,
    data: null
  },
  folderDetail: {
    loading: false,
    data: null
  },
  success: ''
};

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    SET_RECENT_FILE_LOADING: (state, action) => ({
      ...state,
      loading: action.payload
    }),
    SET_MEDIA_ALL_DATA: (state, action) => ({
      ...state,
      allMediaData: action.payload
    }),

    SET_RECENT_FILE_DATA_LOADING: (state, action) => ({
      ...state,
      recentFiles: {
        ...state.recentFiles,
        loading: action.payload
      }
    }),

    SET_RECENT_FILE_DATA: (state, action) => ({
      ...state,
      recentFiles: {
        ...state.recentFiles,
        data: action.payload
      }
    }),

    SET_FOLDER_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_FOLDER_DATA_LOADING: (state, action) => ({
      ...state,
      folders: {
        ...state.folders,
        loading: action.payload
      }
    }),

    SET_FOLDER_DATA: (state, action) => ({
      ...state,
      folders: {
        ...state.folders,
        data: action.payload
      }
    }),

    SET_FILE_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_FILE_DATA_LOADING: (state, action) => ({
      ...state,
      files: {
        ...state.files,
        loading: action.payload
      }
    }),

    SET_FILE_DATA: (state, action) => ({
      ...state,
      files: {
        ...state.files,
        data: action.payload
      }
    }),

    SET_COLLECTION_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_COLLECTION_DATA_LOADING: (state, action) => ({
      ...state,
      collections: {
        ...state.collections,
        loading: action.payload
      }
    }),

    SET_COLLECTION_DATA: (state, action) => ({
      ...state,
      collections: {
        ...state.collections,
        data: action.payload
      }
    }),

    SET_FOLDER_DETAIL_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_FOLDER_DETAIL_DATA_LOADING: (state, action) => ({
      ...state,
      folderDetail: {
        ...state.folderDetail,
        loading: action.payload
      }
    }),

    SET_FOLDER_DETAIL_DATA: (state, action) => ({
      ...state,
      folderDetail: {
        ...state.folderDetail,
        data: action.payload
      }
    }),

    CLEAR_DAM: () => ({
      ...initialState
    })
  }
});

export const {
  SET_RECENT_FILE_LOADING,
  SET_RECENT_FILE_DATA_LOADING,
  SET_RECENT_FILE_DATA,
  SET_FOLDER_DATA,
  SET_FOLDER_DATA_LOADING,
  SET_FOLDER_LOADING,
  SET_COLLECTION_DATA,
  SET_COLLECTION_DATA_LOADING,
  SET_COLLECTION_LOADING,
  CLEAR_DAM,
  SET_FOLDER_DETAIL_DATA,
  SET_FOLDER_DETAIL_DATA_LOADING,
  SET_FOLDER_DETAIL_LOADING,
  SET_FILE_DATA,
  SET_FILE_DATA_LOADING,
  SET_FILE_LOADING,
  SET_MEDIA_ALL_DATA
} = mediaSlice.actions;

export const RECENT_FILES_RESPONSE = (state: RootState) => state.media.media.recentFiles;

export const FOLDER_RESPONSE = (state: RootState) => state.media.media.folders;

export const FILE_RESPONSE = (state: RootState) => state.media.media.files;

export const COLLECTION_RESPONSE = (state: RootState) => state.media.media.collections;

export const FOLDER_DETAIL_RESPONSE = (state: RootState) => state.media.media.folderDetail;

export const MEDIA_LOADER = (state: RootState) => state.media.media.loading;

export const MEDIA_ALL_DATA = (state: RootState) => state.media.media.allMediaData;
