import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';
import { WorkflowListInitialsType } from 'helper/types/companyTab/mediaApprovalWorkflowTypes';

const initialState: WorkflowListInitialsType = {
  loading: false,
  workflowList: {
    loading: false,
    data: []
  },
  approveRejectMediaAssetList: {
    loading: false,
    data: []
  }
};

export const mediaApprovalWorkflowSlice = createSlice({
  name: 'mediaApprovalWorkflow',
  initialState,
  reducers: {
    SET_MEDIA_APPROVAL_WORKFLOW_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_MEDIA_APPROVAL_WORKFLOW_LIST_LOADING: (state, action) => ({
      ...state,
      workflowList: {
        ...state.workflowList,
        loading: action.payload
      }
    }),

    SET_MEDIA_APPROVAL_WORKFLOW_LIST: (state, action) => ({
      ...state,
      workflowList: {
        ...state.workflowList,
        data: action.payload
      }
    }),

    SET_APPROVE_REJECT_MEDIA_ASSET_LIST_LOADING: (state, action) => ({
      ...state,
      approveRejectMediaAssetList: {
        ...state.approveRejectMediaAssetList,
        loading: action.payload
      }
    }),

    SET_APPROVE_REJECT_MEDIA_ASSET_LIST: (state, action) => ({
      ...state,
      approveRejectMediaAssetList: {
        ...state.approveRejectMediaAssetList,
        data: action.payload
      }
    })
  }
});

export const {
  SET_MEDIA_APPROVAL_WORKFLOW_LOADING,
  SET_MEDIA_APPROVAL_WORKFLOW_LIST,
  SET_MEDIA_APPROVAL_WORKFLOW_LIST_LOADING,
  SET_APPROVE_REJECT_MEDIA_ASSET_LIST_LOADING,
  SET_APPROVE_REJECT_MEDIA_ASSET_LIST
} = mediaApprovalWorkflowSlice.actions;

export const MEDIA_APPROVAL_WORKFLOW_LIST = (state: RootState) => state.mediaApprovalWorkFlow;
export const APPROVE_REJECT_MEDIA_ASSETL_LIST = (state: RootState) =>
  state.mediaApprovalWorkFlow.approveRejectMediaAssetList;
