import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  mediaScheduleEventsList: {
    loading: false,
    data: null
  },
  mediaScheduleEventData: {
    loading: false,
    data: null
  },
  mediaScheduleEvents: [],
  response: {
    add: null,
    update: null,
    remove: null
  },
  selectedCompanyId: ''
};

export const mediaScheduleEventsSlice = createSlice({
  name: 'mediaScheduleEvents',
  initialState,
  reducers: {
    SET_MEDIA_SCHEDULE_EVENTS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_COMPANY_MEDIA_ID: (state, action) => ({
      ...state,
      selectedCompanyId: action.payload
    }),

    SET_MEDIA_SCHEDULE_EVENTS_LIST_LOADING: (state, action) => ({
      ...state,
      mediaScheduleEventsList: {
        ...state.mediaScheduleEventsList,
        loading: action.payload
      }
    }),

    SET_MEDIA_SCHEDULE_EVENTS_LIST_DATA: (state, action) => {
      return {
        ...state,
        mediaScheduleEventsList: {
          ...state.mediaScheduleEventsList,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_MEDIA_SCHEDULE_EVENT_DATA_LOADING: (state, action) => ({
      ...state,
      mediaScheduleEventData: {
        ...state.mediaScheduleEventData,
        loading: action.payload
      }
    }),

    SET_MEDIA_SCHEDULE_EVENT_DATA: (state, action) => {
      return {
        ...state,
        mediaScheduleEventData: {
          ...state.mediaScheduleEventData,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_MEDIA_SCHEDULE_EVENTS: (state, action) => {
      return {
        ...state,
        mediaScheduleEvents: action.payload
      };
    },

    SET_MEDIA_SCHEDULE_EVENT_ADD: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: action.payload,
        update: null,
        remove: null
      }
      // lists: action.payload
    }),
    SET_MEDIA_SCHEDULE_EVENT_UPDATE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: action.payload,
        remove: null
      }
    }),
    SET_MEDIA_SCHEDULE_EVENT_DELETE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: null,
        remove: action.payload
      }
    })
  }
});

export const {
  SET_MEDIA_SCHEDULE_EVENTS_LOADING,
  SET_COMPANY_MEDIA_ID,
  SET_MEDIA_SCHEDULE_EVENTS_LIST_LOADING,
  SET_MEDIA_SCHEDULE_EVENTS_LIST_DATA,
  SET_MEDIA_SCHEDULE_EVENT_DATA_LOADING,
  SET_MEDIA_SCHEDULE_EVENT_DATA,
  SET_MEDIA_SCHEDULE_EVENTS,
  SET_MEDIA_SCHEDULE_EVENT_ADD,
  SET_MEDIA_SCHEDULE_EVENT_UPDATE,
  SET_MEDIA_SCHEDULE_EVENT_DELETE
} = mediaScheduleEventsSlice.actions;

export const MEDIA_SCHEDULE_EVENTS_LOADING = (state: RootState) => state.mediaScheduleEvents.loading;

export const GET_SELECTED_COMPANY_MEDIA_ID = (state: RootState) => state.mediaScheduleEvents.selectedCompanyId;

export const MEDIA_SCHEDULE_EVENTS_LIST = (state: RootState) => state.mediaScheduleEvents.mediaScheduleEventsList.data;

export const MEDIA_SCHEDULE_EVENTS_LIST_LOADING = (state: RootState) =>
  state.mediaScheduleEvents.mediaScheduleEventsList.loading;

export const GET_CALENDAR_MEDIA_SCHEDULE_EVENTS = (state: RootState) => state.mediaScheduleEvents.mediaScheduleEvents;

export const MEDIA_SCHEDULE_EVENT_DATA = (state: RootState) => state.mediaScheduleEvents.mediaScheduleEventData.data;

export const MEDIA_SCHEDULE_EVENT_DATA_LOADING = (state: RootState) =>
  state.mediaScheduleEvents.mediaScheduleEventData.loading;

export const MEDIA_GET_SCHEDULE_RESPONSES = (state: RootState) => state.mediaScheduleEvents.response;
