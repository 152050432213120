import { createSlice } from '@reduxjs/toolkit';
import { TextEditorCommentsInitialStateType } from 'helper/types/textEditorComments/textEditorCommentsType';
import { RootState } from '../../rootReducer';

const initialState: TextEditorCommentsInitialStateType = {
  isCommentPopup: false,
  taskApprovalId: null,
  textEditorAssetId: '',
  commentedTextId: '',
  textEditorAssetCommentsList: {
    loading: false,
    data: {
      count: 0,
      next: null,
      prev: null,
      results: []
    }
  },
  commentAddUpdate: {
    loading: false,
    success: false
  },
  deletedCommentData: {
    commentDeleted: false,
    commentData: null
  }
};

export const textEditorCommentsSlice = createSlice({
  name: 'textEditorComments',
  initialState,
  reducers: {
    SET_IS_COMMENT_POPUP: (state, action) => ({
      ...state,
      isCommentPopup: action.payload
    }),

    SET_TASK_APPROVAL_ID: (state, action) => ({
      ...state,
      taskApprovalId: action.payload
    }),

    SET_TEXT_EDITOR_ASSET_ID: (state, action) => ({
      ...state,
      textEditorAssetId: action.payload
    }),

    SET_COMMENTED_TEXT_ID: (state, action) => ({
      ...state,
      textEditorAssetId: '',
      commentedTextId: action.payload
    }),

    SET_TEXT_EDITOR_ASSET_COMMENTS_DATA_LOADING: (state, action) => ({
      ...state,
      textEditorAssetCommentsList: {
        ...state.textEditorAssetCommentsList,
        loading: action.payload
      }
    }),

    SET_TEXT_EDITOR_ASSET_COMMENTS_DATA: (state, action) => ({
      ...state,
      textEditorAssetCommentsList: {
        ...state.textEditorAssetCommentsList,
        data: action.payload
      }
    }),

    SET_COMMENT_ADD_UPDATE_DATA_LOADING: (state, action) => ({
      ...state,
      commentAddUpdate: {
        ...state.commentAddUpdate,
        loading: action.payload
      }
    }),

    SET_COMMENT_ADD_UPDATE_DATA: (state, action) => ({
      ...state,
      commentAddUpdate: {
        ...state.commentAddUpdate,
        success: action.payload
      }
    }),

    SET_IS_COMMENT_DELETE: (state, action) => ({
      ...state,
      deletedCommentData: {
        ...state.deletedCommentData,
        commentDeleted: action.payload
      }
    }),

    SET_COMMENT_DELETE_DATA: (state, action) => ({
      ...state,
      deletedCommentData: {
        ...state.deletedCommentData,
        commentData: action.payload
      }
    })
  }
});

export const {
  SET_IS_COMMENT_POPUP,
  SET_TASK_APPROVAL_ID,
  SET_COMMENTED_TEXT_ID,
  SET_TEXT_EDITOR_ASSET_ID,
  SET_TEXT_EDITOR_ASSET_COMMENTS_DATA_LOADING,
  SET_TEXT_EDITOR_ASSET_COMMENTS_DATA,
  SET_COMMENT_ADD_UPDATE_DATA_LOADING,
  SET_COMMENT_ADD_UPDATE_DATA,
  SET_COMMENT_DELETE_DATA,
  SET_IS_COMMENT_DELETE
} = textEditorCommentsSlice.actions;

export const IS_COMMENT_POPUP = (state: RootState) => state.textEditorComments.isCommentPopup;
export const TASK_APPROVAL_ID = (state: RootState) => state.textEditorComments.taskApprovalId;
export const COMMENTED_TEXT_ID = (state: RootState) => state.textEditorComments.commentedTextId;
export const TEXT_EDITOR_ASSET_ID = (state: RootState) => state.textEditorComments.textEditorAssetId;
export const COMMENT_ADD_UPDATE_DATA = (state: RootState) => state.textEditorComments.commentAddUpdate;
export const COMMENT_DELETE_DATA = (state: RootState) => state.textEditorComments.deletedCommentData.commentData;
export const IS_COMMENT_DELETE_DATA = (state: RootState) => state.textEditorComments.deletedCommentData.commentDeleted;
export const TEXT_EDITOR_ASSET_COMMENTS_DATA = (state: RootState) =>
  state.textEditorComments.textEditorAssetCommentsList;
