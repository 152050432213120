import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  jobsEventsList: {
    loading: false,
    data: null
  }
};

export const allEventsSlice = createSlice({
  name: 'allEvents',
  initialState,
  reducers: {
    SET_ALL_EVENTS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_JOBS_EVENTS_LIST_LOADING: (state, action) => ({
      ...state,
      jobsEventsList: {
        ...state.jobsEventsList,
        loading: action.payload
      }
    }),

    SET_JOBS_EVENTS_LIST_DATA: (state, action) => {
      return {
        ...state,
        jobsEventsList: {
          ...state.jobsEventsList,
          hasData: true,
          data: action.payload
        }
      };
    }
  }
});

export const { SET_ALL_EVENTS_LOADING, SET_JOBS_EVENTS_LIST_LOADING, SET_JOBS_EVENTS_LIST_DATA } = allEventsSlice.actions;

export const ALL_EVENTS_LOADING = (state: RootState) => state.allEvents.loading;

export const JOBS_EVENTS_LIST = (state: RootState) => state.allEvents.jobsEventsList.data;

export const JOBS_EVENTS_LIST_LOADING = (state: RootState) => state.allEvents.jobsEventsList.loading;
