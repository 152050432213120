import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Figtree, sans-serif'
  },
  palette: {
    primary: {
      main: '#0D6EFD'
    },
    success: {
      main: '#59CF65'
    },
    error: {
      main: '#D14F4F'
    },
    warning: {
      main: '#D99836'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
          maxHeight: '3rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8
        },
        contained: {
          border: 1
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' }
      }
    }
  }
});
