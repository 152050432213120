import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  jobAssetsList: {
    loading: false,
    data: null
  },
  userAssociatedAssetList: {
    loading: false,
    data: null
  }
};

export const jobAssetsSlice = createSlice({
  name: 'jobAssets',
  initialState,
  reducers: {
    SET_JOBS_ASSETS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_JOBS_PARENT_CHILD_ASSETS_LIST_LOADING: (state, action) => ({
      ...state,
      jobAssetsList: {
        ...state.jobAssetsList,
        loading: action.payload
      }
    }),

    SET_JOBS_PARENT_CHILD_ASSETS_LIST: (state, action) => {
      return {
        ...state,
        jobAssetsList: {
          ...state.jobAssetsList,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_USER_ASSOCIATED_ASSETS_LIST_LOADING: (state, action) => ({
      ...state,
      userAssociatedAssetList: {
        ...state.userAssociatedAssetList,
        loading: action.payload
      }
    }),

    SET_USER_ASSOCIATED_ASSETS_LIST: (state, action) => {
      return {
        ...state,
        userAssociatedAssetList: {
          ...state.userAssociatedAssetList,
          hasData: true,
          data: action.payload
        }
      };
    }
  }
});

export const {
  SET_JOBS_ASSETS_LOADING,
  SET_JOBS_PARENT_CHILD_ASSETS_LIST_LOADING,
  SET_JOBS_PARENT_CHILD_ASSETS_LIST,
  SET_USER_ASSOCIATED_ASSETS_LIST_LOADING,
  SET_USER_ASSOCIATED_ASSETS_LIST
} = jobAssetsSlice.actions;

export const JOBS_ASSETS_LOADING = (state: RootState) => state.homePage.jobAssetsList.loading;

export const JOBS_ASSETS_LIST = (state: RootState) => state.homePage.jobAssetsList.jobAssetsList.data;

export const JOBS_ASSETS_LIST_LOADING = (state: RootState) => state.homePage.jobAssetsList.jobAssetsList.loading;

export const USER_ASSETS_LIST = (state: RootState) => state.homePage.jobAssetsList.userAssociatedAssetList.data;

export const USER_ASSETS_LIST_LOADING = (state: RootState) =>
  state.homePage.jobAssetsList.userAssociatedAssetList.loading;
