import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  adhocEventsList: {
    loading: false,
    data: null
  },
  adhocEventData: {
    loading: false,
    data: null
  },
  scheduleEvents: [],
  response: {
    add: null,
    update: null,
    remove: null
  }
};

export const adhocEventsSlice = createSlice({
  name: 'adhocEvents',
  initialState,
  reducers: {
    SET_ADHOC_EVENTS_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_ADHOC_EVENTS_LIST_LOADING: (state, action) => ({
      ...state,
      adhocEventsList: {
        ...state.adhocEventsList,
        loading: action.payload
      }
    }),

    SET_ADHOC_EVENTS_LIST_DATA: (state, action) => {
      return {
        ...state,
        adhocEventsList: {
          ...state.adhocEventsList,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_ADHOC_EVENT_DATA_LOADING: (state, action) => ({
      ...state,
      adhocEventData: {
        ...state.adhocEventData,
        loading: action.payload
      }
    }),

    SET_ADHOC_EVENT_DATA: (state, action) => {
      return {
        ...state,
        adhocEventData: {
          ...state.adhocEventData,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_ADHOC_EVENTS: (state, action) => {
      return {
        ...state,
        scheduleEvents: action.payload
      };
    },

    SET_ADHOC_EVENT_ADD: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: action.payload,
        update: null,
        remove: null
      }
      // lists: action.payload
    }),
    SET_ADHOC_EVENT_UPDATE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: action.payload,
        remove: null
      }
    }),
    SET_ADHOC_EVENT_DELETE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: null,
        remove: action.payload
      }
    })
  }
});

export const {
  SET_ADHOC_EVENTS_LOADING,
  SET_ADHOC_EVENTS_LIST_LOADING,
  SET_ADHOC_EVENTS_LIST_DATA,
  SET_ADHOC_EVENT_DATA_LOADING,
  SET_ADHOC_EVENT_DATA,
  SET_ADHOC_EVENTS,
  SET_ADHOC_EVENT_ADD,
  SET_ADHOC_EVENT_UPDATE,
  SET_ADHOC_EVENT_DELETE
} = adhocEventsSlice.actions;

export const ADHOC_EVENTS_LOADING = (state: RootState) => state.adhocEvents.loading;

export const ADHOC_EVENTS_LIST = (state: RootState) => state.adhocEvents.adhocEventsList.data;

export const ADHOC_EVENTS_LIST_LOADING = (state: RootState) => state.adhocEvents.adhocEventsList.loading;

export const GET_CALENDAR_ADHOC_EVENTS = (state: RootState) => state.adhocEvents.scheduleEvents;

export const ADHOC_EVENT_DATA = (state: RootState) => state.adhocEvents.adhocEventData.data;

export const ADHOC_EVENT_DATA_LOADING = (state: RootState) => state.adhocEvents.adhocEventData.loading;

export const GET_ADHOC_RESPONSES = (state: RootState) => state.adhocEvents.response;
