import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';
import { CompanyListInitialsType } from 'helper/types/companyTab/companiesType';
import { ChannelSettingsInitialStateT } from 'helper/types/companyTab/channelSettingsType';

const initialState: ChannelSettingsInitialStateT = {
  channelSettingsList: {
    loading: false,
    data: {
      count: 0,
      prev: null,
      next: null,
      results: []
    }
  }
};

export const channelSettingsTabSlice = createSlice({
  name: 'channelSettingsTab',
  initialState,
  reducers: {
    SET_CHANNEL_SETTINGS_LIST_LOADING: (state, action) => ({
      ...state,
      channelSettingsList: {
        ...state.channelSettingsList,
        loading: action.payload
      }
    }),

    SET_CHANNEL_SETTINGS_LIST_DATA: (state, action) => ({
      ...state,
      channelSettingsList: {
        ...state.channelSettingsList,
        data: action.payload
      }
    })
  }
});

export const { SET_CHANNEL_SETTINGS_LIST_LOADING, SET_CHANNEL_SETTINGS_LIST_DATA } = channelSettingsTabSlice.actions;

export const CHANNEL_SETTINGS_LIST = (state: RootState) => state.companyTab.channelSettingsTab.channelSettingsList;
