import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  NDAsList: {
    loading: false,
    data: null
  },
  NDAData: {
    loading: false,
    data: null
  },
  //   scheduleEvents: [],
  response: {
    add: null,
    update: null,
    remove: null
  },
  signNDA: {
    loading: false,
    data: null
  }
};

export const NDASlice = createSlice({
  name: 'nda',
  initialState,
  reducers: {
    SET_NDA_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_NDA_LIST_LOADING: (state, action) => ({
      ...state,
      NDAsList: {
        ...state.NDAsList,
        loading: action.payload
      }
    }),

    SET_NDA_LIST_DATA: (state, action) => {
      return {
        ...state,
        NDAsList: {
          ...state.NDAsList,
          hasData: true,
          data: action.payload
        }
      };
    },

    SET_NDA_DATA_LOADING: (state, action) => ({
      ...state,
      NDAData: {
        ...state.NDAData,
        loading: action.payload
      }
    }),

    SET_NDA_DATA: (state, action) => {
      return {
        ...state,
        NDAData: {
          ...state.NDAData,
          hasData: true,
          data: action.payload
        }
      };
    },
    SET_SIGN_NDA_LOADING: (state, action) => {
      return {
        ...state,
        signNDA: {
          ...state.signNDA,
          loading: action.payload
        }
      };
    },

    SET_SIGN_NDA: (state, action) => {
      return {
        ...state,
        signNDA: {
          ...state.signNDA,
          data: action.payload
        }
      };
    },

    SET_NDA_ADD: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: action.payload,
        update: null,
        remove: null
      }
      // lists: action.payload
    }),
    SET_NDA_UPDATE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: action.payload,
        remove: null
      }
    }),
    SET_NDA_DELETE: (state, action) => ({
      ...state,
      response: {
        ...state.response,
        add: null,
        update: null,
        remove: action.payload
      }
    })
  }
});

export const {
  SET_NDA_LOADING,
  SET_NDA_LIST_LOADING,
  SET_NDA_LIST_DATA,
  SET_NDA_DATA_LOADING,
  SET_NDA_DATA,
  SET_SIGN_NDA_LOADING,
  SET_SIGN_NDA,
  SET_NDA_ADD,
  SET_NDA_UPDATE,
  SET_NDA_DELETE
} = NDASlice.actions;

export const NDA_LOADING = (state: RootState) => state.nda.loading;

export const NDA_LIST = (state: RootState) => state.nda.NDAsList.data;

export const NDA_LIST_LOADING = (state: RootState) => state.nda.NDAsList.loading;

// export const GET_CALENDAR_NDA = (state: RootState) => state.nda.scheduleEvents;

export const NDA_DATA = (state: RootState) => state.nda.NDAData.data;

export const NDA_DATA_LOADING = (state: RootState) => state.nda.NDAData.loading;

export const GET_NDA_RESPONSES = (state: RootState) => state.nda.response;

export const GET_NDA_SIGN_SUCCESS_RESPONSE = (state: RootState) => state.nda.signNDA;
