import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/rootReducer';

const initialState = {
  loading: false,
  publicJobDetails: {
    loading: false,
    details: null,
    error: null
  }
};

export const publicJobDetailsSlice = createSlice({
  name: 'publicJobDetails',
  initialState,
  reducers: {
    SET_PUBLIC_JOB_DETAILS_MAIN_LOADING: (state, action) => {
      state.loading = action.payload;
    },

    SET_PUBLIC_JOB_DETAILS_LOADING: (state, action) => ({
      ...state,
      publicJobDetails: {
        ...state.publicJobDetails,
        loading: action.payload
      }
    }),

    SET_PUBLIC_JOB_DETAILS_DATA: (state, action) => {
      return {
        ...state,
        publicJobDetails: {
          ...state.publicJobDetails,
          hasData: true,
          details: action.payload
        }
      };
    },

    SET_PUBLIC_JOB_DETAILS_DATA_ERROR: (state, action) => {
      return {
        ...state,
        publicJobDetails: {
          ...state.publicJobDetails,
          error: action.payload
        }
      };
    }
  }
});

export const {
  SET_PUBLIC_JOB_DETAILS_MAIN_LOADING,
  SET_PUBLIC_JOB_DETAILS_LOADING,
  SET_PUBLIC_JOB_DETAILS_DATA,
  SET_PUBLIC_JOB_DETAILS_DATA_ERROR
} = publicJobDetailsSlice.actions;

export const PUBLIC_JOB_DETAILS = (state: RootState) => state.publicJobDetails.publicJobDetails;
