import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import './assets/css/tailwind.css';

// import 'api/fb/fbSDKInit';

window.Buffer = window.Buffer || require('buffer').Buffer;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>
);
